import { IonContent, IonPage, IonButton, IonInput, IonLabel, IonAvatar, IonIcon, IonImg, IonToast, isPlatform, } from '@ionic/react';
import React, {useEffect, useRef, useState} from 'react';
import './Login.css';
import imgTopo from './imgs/LogoCor.png';
import imgGoogle from './imgs/Google.svg';
import imgFacebook from './imgs/Facebook.svg';
import { Link, useHistory } from 'react-router-dom';
import { logar, requestService } from '../../../Utils/Services';
import '@capacitor-community/http';
import { Plugins } from '@capacitor/core';
import { HttpResponse } from '@capacitor-community/http';


const Login: React.FC = () => {
  const { Http } = Plugins;
  const history = useHistory();
  const [telefone, setTelefone] = useState("");
  const [cpf, setCPF] = useState("");
  const [primeiroFoco, setPrimeiroFoco] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [textToast, setTextToast] = useState("");
  const [botaoAtivo, setBotaoAtivo] = useState(true);
  const [textBotao, setTextBotao] = useState("Próximo");
  const campoCPF = useRef<any>(null);
  const campoTelefone = useRef<any>(null);
  const TAB_KEY_CODE = 9;
  const ENTER_KEY_CODE = 13;

  function onChangeCPF(e: any) {

    //console.log(cpf.length);
    //console.log(e.target.value.length);
    setPrimeiroFoco(true);
    e.persist();
    e.preventDefault();

    if (e.keyCode === ENTER_KEY_CODE || e.keyCode === TAB_KEY_CODE) {
      e.target.value = e.target.value.substring(0, 14);
      setCPF(e.target.value.substring(0, 14));
      campoTelefone.current.setFocus();
    }

    if (e.target.value.length > 14) {
      e.target.value = e.target.value.substring(0, 14);
      setCPF(e.target.value.substring(0, 14));
      return;
    }

    let val = e.target.value;
    var cpfval = val.replace(/\D/g, "")
    cpfval = cpfval.replace(/(\d{3})(\d)/, "$1.$2")
    cpfval = cpfval.replace(/(\d{3})(\d)/, "$1.$2")
    cpfval = cpfval.replace(/(\d{3})(\d{1,2})$/, "$1-$2")
    //console.log(cpfval);
    setCPF(cpfval);
  }

  // function onKeyUpSenha(e: any){

  //   e.persist();
  //   e.preventDefault();

  //   if (e.keyCode === ENTER_KEY_CODE){
  //     e.target.value = e.target.value.substring(0,15);
  //     setSenha(e.target.value.substring(0,15));
  //     logarEvent();
  //   }

  //   if (e.target.value.length > 15) {
  //     e.target.value = e.target.value.substring(0,15);
  //     setSenha(e.target.value.substring(0,15));
  //     return;
  //   }

  //   let replaceAt = function(str:any, index:any, replacement:any) {
  //     return str.substr(0, index) + replacement + str.substr(index + replacement.length);
  //   }

  //   let form = "(__) _____-____";
    
  //   let val = e.target.value;
    
  //   let i = 1;
  //   if(senha.length > 0){
  //     i = 0;
  //   }
  //   let j = 0;
  //   let aumentou = false;

  //   if(val.length > senha.length){

  //     for(; i < form.length; i++){
  //       if(j<val.length){

  //         form = replaceAt(form, i, val.charAt(j))

  //         if(form.charAt(i+1) === '(' || form.charAt(i+1) === ')' || form.charAt(i+1) === '-'){
  //           if(val.charAt(j+1) !== form.charAt(i+1)){
  //             if(form.charAt(i+1) === ')'){
  //               i++;
  //             }
  //             i++;
  //             aumentou = true;
  //             j++;
  //           }else{
  //             j++
  //           }
  //         }else{
  //           j++;
  //         }
  //       }else{
  //         break;
  //       }
  //     }
  //   }else{
  //     if(senha.length === 1 || senha.length === 11){
  //       form = val.substr(0, val.length-1);
  //     }else if(senha.length === 5){
  //       form = val.substr(0, val.length-2);
  //     }else{
  //       form = val;
  //     }
  //     i = form.length;
  //   }


  //   setSenha(form.substr(0, i));

  //   //console.log(senha.length);
  //   //console.log(e.target.value.length);
  // }

  const logarEvent = () => {
    if (botaoAtivo)
    {
      setBotaoAtivo(false);
      setTextBotao("Aguarde...");

      let usuarioCPF = cpf.replace("-","").replace(".","").replace(".","");

      const objectRequest = {
        telefone: "55"+telefone.replace("(","").replace(")","").replace(" ","").replace("-",""),
        dispositivoIos: true // isPlatform('ios')
      }
      //console.log(objectRequest);

      requestService(
          logar.url.replace("{cpf}",usuarioCPF),
          {
            method: logar.method,
            headers: [
              ["token", localStorage.getItem("token")||""]
            ],
            body: JSON.stringify(objectRequest),
            //credentials: 'include'            
          },
          history,
          (response:any) => {
            //console.log(response);
            if(response){
           
              window.localStorage.setItem("cpf", usuarioCPF);
              window.localStorage.setItem("token", response.token);
              window.localStorage.setItem("perfil", response.perfil);
              window.localStorage.setItem("telefone", "55" + telefone.replace(/\(/g, "").replace(/\)/g, "").replace(/\ /g, "").replace(/\-/g, ""));
              window.localStorage.setItem("operacoesNovo", '1');
              if (response.perfil){
                setCPF("");
                setTelefone("");
                setBotaoAtivo(true);
                setTextBotao("Entrar");
                // if(response.perfil == "Usuário"){
                //   history.replace("/validar-login");
                // }
                // else{
                  history.push("/validar-otp");
                // }
              }
              else{
                history.replace("/cliente/operacoes");
              }
            }else if(response.status === 400){
              
            }
          },
          (error:any) => {
            //console.log(error)
            setTextToast("Usuário não cadastrado ou informações inválidas");
            setShowToast(true);
            setBotaoAtivo(true);
            setTextBotao("Entrar");
          }
      )
    }
  }

  function onKeyUpTelefone(e: any) {
    e.persist();
    e.preventDefault();


    if (e.keyCode === ENTER_KEY_CODE) {
      e.target.value = e.target.value.substring(0, 15);
      setTelefone(e.target.value.substring(0, 15));
      logarEvent();
    }

    if (e.target.value.length > 15) {
      e.target.value = e.target.value.substring(0, 15);
      setTelefone(e.target.value.substring(0, 15));
      return;
    }

    let val = e.target.value;
    var telVal = val.replace(/\D/g, "")
      .replace(/(\d{2})(\d)/, "($1) $2")
      .replace(/(\d{5})(\d)/, "$1-$2")
      .replace(/(-\d{4})(\d+?)$/, "$1");
    //console.log(cpfval);
    setTelefone(telVal);
  }


  return (
    <IonPage>
      <IonContent className={"Login"}>
        <div id="blocoTopo"></div>
        <img src={imgTopo} alt="Logo"/>
        <IonLabel className="titulo">Acesse aqui o Saidamax</IonLabel>
        <div className="input">
          <span>CPF</span>
          <IonInput type="tel" maxlength={14} placeholder="___.___.___-__" required value={cpf} onKeyUp={onChangeCPF} onIonInput={(e: any) => setCPF(e.target.value)} />
        </div>
        <div className="input">
          <span>Telefone</span>
          <IonInput ref={(ref) => campoTelefone.current = ref} type="tel" maxlength={15} placeholder="(__) _____-____" required value={telefone} onKeyUp={onKeyUpTelefone} onIonInput={(e: any) => setTelefone(e.target.value)} />
        </div>
        <IonButton disabled={!botaoAtivo} onClick={() => {logarEvent();}}>{textBotao}</IonButton>
        
        <div className="divisoria">
          <span></span>
          
        </div>
        
        <IonToast
        isOpen={showToast}
        position="middle" //top, bottom, middle
        onDidDismiss={() => setShowToast(false)}
        message={textToast}
        duration={3000}
        />
      </IonContent>
    </IonPage>
  );
};

export default Login;
