import { IonContent, IonPage, IonSlides, IonButton, IonSlide, IonLabel } from '@ionic/react';
import React, {useRef, useState } from 'react';
import './Onboarding.css';
import { Link, useHistory  } from 'react-router-dom';
import { onboarding_all, requestService } from '../../../Utils/Services';

class Onboarding extends React.Component<any, any>{
  private slides: React.RefObject<any>;

  constructor(props:any){
    super(props);

    this.slides = React.createRef();
    this.state = {
      count: 0,
      onboardingPages: [],
      textButton: "Próximo",
      classIrLogin: "",
    }

    requestService(
      onboarding_all.url,
      {
        method: onboarding_all.method
      },
      props.history,
      (response:any) => {
        this.setState({
          onboardingPages: response.onboarding
        });
      },
      (error:any) => console.log(error)
    );

    this.onClickEvent = this.onClickEvent.bind(this);
    this.nextSlide = this.nextSlide.bind(this);
    this.loadSlides = this.loadSlides.bind(this);
  }

  onClickEvent(){
    this.slides.current!.isEnd().then(
      (isEnd:any) => {
        if(isEnd){
          this.props.history.replace('/login');
        }else{
          this.slides.current.slideTo(this.state.count+1);
          this.setState({count:this.state.count+1})
        }
      }
    );
  }

  nextSlide() { 
    
    this.slides.current.isEnd().then(
      (isEnd:any) => {
        if(isEnd){
          this.setState({
            textButton: "Entrar",
            classIrLogin: "invisivel"
          });
        }else{
          this.setState({
            textButton: "Próximo",
            classIrLogin: ""
          });
        }
      }
    );
  }

  loadSlides() {
    if(this.state.onboardingPages.length > 0)
    return (
      <IonSlides className={"OnboardingSlides"} pager={true} ref={this.slides} onIonSlideWillChange={this.nextSlide}>
        {this.state.onboardingPages.map((value:any, index:any) => (<IonSlide key={index}>
          <h1>{value.titulo}</h1>
          <h2>{value.descricao}</h2>
          <img src={value.caminho} alt="img"/>
        </IonSlide>))}
      </IonSlides>
    )
  }

  render(){
    return (
      <IonPage>
        <IonContent className={"Onboarding"}>
          
            {this.loadSlides()}
          <IonButton onClick={this.onClickEvent}>{this.state.textButton}</IonButton>
          <Link to="/login"><IonLabel className={this.state.classIrLogin}>Ir para o Login</IonLabel></Link>
        </IonContent>
      </IonPage>
    );
  }
}

export default Onboarding;
