import { IonContent, IonPage, IonButton, IonInput, IonLabel, IonToast } from '@ionic/react';
import React, { useEffect, useRef, useState } from 'react';
import './ValidarLogin.css';
import imgTopo from './imgs/topo.png';
import {  useHistory } from 'react-router-dom';
import { logar, requestService } from '../../../Utils/Services';


const ValidarLogin: React.FC = () => {

  const inputRef = useRef<any>(null);

  useEffect(() => {inputRef.current.setFocus()
      //setTimeout(() => inputRef.current.setFocus(), 100);
  });

  const history = useHistory();

  const [showToast, setShowToast] = useState(false);
  const [textToast, setTextToast] = useState("");
  const [botaoAtivo, setBotaoAtivo] = useState(true);
  const [textBotao, setTextBotao] = useState("Entrar");
  const [codigo, setCodigo] = useState("");

  const validar = () => {
    setBotaoAtivo(false);
    setTextBotao("Aguarde...");

    const objectRequest = {
      password: codigo.toString()
    }
    requestService(
      logar.url.replace("{cpf}",window.localStorage.getItem("cpf")||""),
      {
        method: logar.method,
        headers: [
          ["token", window.localStorage.getItem("token")]
        ],
        body: JSON.stringify(objectRequest),
        credentials: 'include'
      },
      history,
      (response:any)=>{
        //window.localStorage.clear();
        window.localStorage.setItem("logado", "true")
        window.localStorage.setItem("token", response.token);
        window.localStorage.setItem("categoria", response.categoria);
        history.replace("/cliente/operacoes");
      },
      (error:any) => {
        setTextToast("Token invalido");
        setShowToast(true);
        setCodigo("");
        setBotaoAtivo(true);
        setTextBotao("Entrar");
      }
    );
  }

  function onChangeCodigo(e: any){
    e.persist();
    e.preventDefault();
    setCodigo(e.target.value);

    if (e.keyCode === 13){
      if (botaoAtivo) validar();
    }
  }

  return (
    <IonPage>
      <IonContent className={"ValidarLogin"}>
        <div id="blocoTopo"></div>
        <img src={imgTopo} alt="Logo"/>
        <IonLabel className="titulo">Validando sua senha</IonLabel>
        <IonLabel className="titulo">Insira sua senha para acessar:</IonLabel>
        <div className="input">
          <span>Password</span>
          <IonInput ref={(ref) => inputRef.current = ref} type="password" maxlength={50} required value={codigo} onKeyUp={onChangeCodigo}/>
        </div>
        <IonButton disabled={!botaoAtivo} onClick={() => {validar();}}>{textBotao}</IonButton>
        <IonToast
        isOpen={showToast}
        position="middle" //top, bottom, middle
        onDidDismiss={() => setShowToast(false)}
        message={textToast}
        duration={3000}
        />
      </IonContent>
    </IonPage>
  );
};

export default ValidarLogin;
