import React, { useState } from 'react';
import { Redirect, Route, useHistory } from 'react-router-dom';
import { IonApp, IonContent, IonIcon, IonImg, IonPage, IonRouterOutlet, isPlatform } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import './App.css';
import logo from './theme/icons/Logo.svg'
import logoTransparente from './theme/icons/Logo.png'
import { ScreenOrientation } from '@ionic-native/screen-orientation';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import Cadastro from './pages/Inicio/Cadastro/Cadastro';
import Onboarding from './pages/Inicio/Onboarding/Onboarding';
import Login from './pages/Inicio/Login/Login';
import Home from './pages/Cliente/Home/Home';
import ValidarCelular from './pages/Inicio/ValidarCelular/ValidarCelular';
import ValidarLogin from './pages/Inicio/ValidarLogin/ValidarLogin';
import ValidarOtp from './pages/Inicio/ValidarOtp/ValidarOtp';
import { requestService, autenticado } from './Utils/Services';

class App extends React.Component<any, any>{
  constructor(props: any) {
    super(props);
    this.state = {
      loaded: false,
      destino: "/"
    }

    const tempo = 2500;

    var token: string = "";
    var tkn = localStorage.getItem("token");
    var cpf = localStorage.getItem("cpf") || "";
    if (typeof (tkn) === "string") {
      token = tkn;
    }

    requestService(
      autenticado.url,
      {
        method: autenticado.method.replace("{cpf}", cpf),
        headers: [
          ["token", token]
        ],
        credentials: 'include'
      },
      this.props.history,
      (response: any) => {
        //console.log(response);
        if (response.Item && response.Item.lojas) window.localStorage.setItem("lojas", JSON.stringify(response.Item.lojas));
        window.localStorage.setItem("acessos", JSON.stringify(response.acessos || ""));
        //if (response.OperacoesNovo && response.operacoesNovo) window.localStorage.setItem("operacoesNovo", JSON.stringify(response.operacoesNovo));
        if (response.status === 200) {

          if (window.localStorage.getItem("categoria") == "parceiro") {
            setTimeout(() => {
              this.setState({
                loaded: true,
                destino: "/cliente/validartokenmotorista"
              });
            },
              tempo);
          }
          else {
            setTimeout(() => {
              this.setState({
                loaded: true,
                destino: "/cliente"
              });
            },
              tempo);
          }
        }

      },
      (error: any) => {
        //window.localStorage.setItem("logado", "false");
        if (error.status === 400) {
          setTimeout(() => {
            this.setState({
              loaded: true,
              destino: "/login"
            });
          },
            tempo);
        } else
          if (error.status === 401 || error.status === 403) {
            setTimeout(() => {
              this.setState({
                loaded: true,
                destino: "/login"
              });
            },
              tempo);
          }
      },
      undefined,
      true
    );
    this.getSplash = this.getSplash.bind(this);
    if (!isPlatform('mobileweb') && isPlatform('mobile')) {
      ScreenOrientation.lock(ScreenOrientation.ORIENTATIONS.PORTRAIT);
    }
  }

  getSplash() {
    if (!this.state.loaded) {
      //console.log("loading")
      return (<IonPage>
        <IonContent className="Intro">
          <div className={"background-intro"}></div>
          <IonImg src={logoTransparente}></IonImg>
        </IonContent>
      </IonPage>);
    } else {
      //console.log("passou aqui")
      return (<Redirect to={this.state.destino} />);
    }
  }

  render() {
    return (
      <IonApp>
        <IonReactRouter>
          <IonRouterOutlet >
            <Route path="/cliente" component={Home} />
            <Route path="/onboarding" component={Onboarding} exact={true} />
            <Route path="/login" component={Login} exact={true} />
            <Route path="/cadastro" component={Cadastro} exact={true} />
            <Route path="/validar-celular" component={ValidarCelular} exact={true} />
            <Route path="/validar-login" component={ValidarLogin} exact={true} />
            <Route path="/validar-Otp" component={ValidarOtp} exact={true} />
          </IonRouterOutlet>


          {this.getSplash()}
        </IonReactRouter>
      </IonApp>
    );
  }
}

export default App;
