import { IonBackButton, IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonChip, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonItem, IonLabel, IonModal, IonPage, IonRefresher, IonRefresherContent, IonRow, IonSelect, IonSelectOption, IonTitle, IonToolbar, useIonViewDidEnter, useIonViewWillLeave } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import './Monitor.css';
import { calendar, grid, storefront } from 'ionicons/icons';
import OperacoesFiltroLojas from '../Operacoes/OperacoesFiltroLojas';
import { urlBase } from '../../../../../Utils/Services';
import dayjs from 'dayjs';
import { Doughnut, Line } from 'react-chartjs-2';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ptBR from 'date-fns/locale/pt-BR';
import ChartDataLabels from 'chartjs-plugin-datalabels';

const Monitor: React.FC = () => {

  const history = useHistory();

  const [stateLojas, setStateLojas] = useState<string[]>(JSON.parse(window.localStorage.getItem("lojas") || "[]").map((valor: any) => (valor.clienteLojaId)));

  const [stateDashboard, setStateDashboard] = useState<{}[]>([]);
  const [stateData, setStateData] = useState<Date>(new Date());
  const [showModalFiltroLojas, setShowModalFiltroLojas] = useState(false);
  const [stateMinutos, setStateMinutos] = useState(0);
  const [stateAutoReload, setStateAutoReload] = useState(false);

  useIonViewDidEnter(() => {
    CarregarDashboardV2();
    setStateAutoReload(true);
    //setShowModalFiltroLojas(true);
  });

  useIonViewWillLeave(() => setStateAutoReload(false));

  useEffect(() => {
    if (stateAutoReload) {
      const interval = setInterval(() => {
        CarregarDashboardV2();
      }, 90000); // 1 segundo = 1000 milissegundos

      // Limpeza do intervalo quando o componente for desmontado
      return () => clearInterval(interval);
    }
  }, [stateAutoReload]);

  async function CarregarDashboardV2(lojas?: any, dateParam?: Date) {
    let lojasArray: any[] = [];

    let lojasCarregadas = (lojas || stateLojas).map(async (value: any) => {
      let paramFiltro = "Concluido";
      let paramLojas = Buffer.from(JSON.stringify(value)).toString('base64');
      let paramData
      if (dateParam) {
        paramData = dayjs(dateParam).format('YYYY-MM-DD');;
      }
      else {
        paramData = dayjs(stateData).format('YYYY-MM-DD');;
      }
      let paramPeriodo = "diario";

      let retorno: any = await BuscarOperacoesV2(paramFiltro, paramLojas, paramData, paramPeriodo);
      let responsePayload = await retorno.json();

      let retorno2: any = await BuscarOperacoesV2("Em", paramLojas, paramData, paramPeriodo);
      let responsePayload2 = await retorno2.json();

      if (responsePayload.Items && responsePayload.Items.length > 0) {
        responsePayload.Items.map((item: any) => lojasArray.push(item));
      }

      if (responsePayload2.Items && responsePayload2.Items.length > 0) {
        responsePayload2.Items.map((item: any) => lojasArray.push(item));
      }
    });

    Promise.all(lojasCarregadas).then(() => {
      setStateDashboard(lojasArray);
      let minutos = 0;
      lojasArray.map((item: any) => {
        let dataInicio = dayjs(item.dataInicio);
        let dataFim = dayjs(item.dataEncerramento || new Date());
        minutos += dataFim.diff(dataInicio, "minutes");
      });
      setStateMinutos(minutos);
    });
  }

  async function BuscarOperacoesV2(paramFiltro: any, paramLojas: any, paramData: any, paramPeriodo: string) {

    let fecthOptions: any = {
      method: urlBase.method,
      headers: [
        ["token", localStorage.getItem("token") || ""]
      ],
      credentials: 'include'
    };

    return await fetch(urlBase.url + "/operacoes?filtro=" + paramFiltro + "&lojas=" + paramLojas + "&data=" + paramData + "&periodo=" + paramPeriodo, fecthOptions);
  }

  return (
    <IonPage class="monitor">
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot='start'>
            <IonBackButton></IonBackButton>
          </IonButtons>
          <IonTitle>Monitor</IonTitle>
          <IonButtons slot="end">
            <IonButton hidden={(window.localStorage.getItem("lojas") && JSON.parse(window.localStorage.getItem("lojas") || "").length > 0) ? false : true} onClick={() => setShowModalFiltroLojas(true)} >
              <IonIcon slot="icon-only" icon={storefront} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonRefresher slot="fixed" onIonRefresh={() => { }}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>

        <IonModal isOpen={showModalFiltroLojas} onDidDismiss={() => setShowModalFiltroLojas(false)}>
          <OperacoesFiltroLojas lojas={stateLojas} setLojas={setStateLojas} setShowModalFiltroLojas={setShowModalFiltroLojas} updateDataFunction={CarregarDashboardV2} />
        </IonModal>

        <IonGrid>
          <IonRow>
            <IonCol>
              <IonCard>
                <IonCardHeader style={{ textAlign: 'center', background: '#1D3460' }}><IonLabel style={{ color: '#FFF' }}>Operações em andamento</IonLabel></IonCardHeader>
                <IonCardContent style={{ marginTop: '15px', textAlign: 'center', fontWeight: 'bold', fontSize: '20px' }}><IonLabel>{stateDashboard.filter((item: any) => item.status == "Em andamento").length}</IonLabel></IonCardContent>
              </IonCard>
            </IonCol>
            <IonCol>
              <IonCard>
                <IonCardHeader style={{ textAlign: 'center', background: '#1D3460' }}><IonLabel style={{ color: '#FFF' }}>Operações totais</IonLabel></IonCardHeader>
                <IonCardContent style={{ marginTop: '15px', textAlign: 'center', fontWeight: 'bold', fontSize: '20px' }}><IonLabel>{stateDashboard.length}</IonLabel></IonCardContent>
              </IonCard>
            </IonCol>
            <IonCol>
              <IonCard>
                <IonCardHeader style={{ textAlign: 'center', background: '#1D3460' }}><IonLabel style={{ color: '#FFF' }}>Duração média</IonLabel></IonCardHeader>
                <IonCardContent style={{ marginTop: '15px', textAlign: 'center', fontWeight: 'bold', fontSize: '20px' }}><IonLabel>{Math.round(stateMinutos / stateDashboard.length) + " minutos"}</IonLabel></IonCardContent>
              </IonCard>
            </IonCol>
          </IonRow>
        </IonGrid>

        <IonGrid class='monitorListaEspera'>
          <IonRow style={{background:'#ff6a14'}}>
            <IonCol size='1' style={{textAlign:'center'}}>#</IonCol>
            <IonCol size='1'>Situação</IonCol>
            <IonCol size='7'>Fornecedor</IonCol>
            <IonCol size='1'>Chegada</IonCol>
            <IonCol size='1'>Duração</IonCol>
            <IonCol size='1'>Loja</IonCol>
          </IonRow>

          {stateDashboard.filter((item: any) => item.status == "Em andamento").sort((a: any, b: any) => ((a.dataInicio || "").toUpperCase() > (b.dataInicio || "").toUpperCase()) ? 1 : -1).slice(0, 10).map((item: any, indice: any) => {
            return (
              <IonRow>
                <IonCol size='1' style={{textAlign:'center'}}>{indice + 1}</IonCol>
                <IonCol size='1'>{item?.anotacoes?.find((item: any) => { return item.anotacao.toLowerCase().includes('descarregar') }) ? "Conferência" : "Aguardando"}</IonCol>
                <IonCol size='7' style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{item.notasAdicionais[0].razaoSocial || ""}</IonCol>
                <IonCol size='1'>{new Date(item.dataInicio).toLocaleTimeString()}</IonCol>
                <IonCol size='1'>{Math.round((+new Date() - +new Date(item.dataInicio)) / 1000 / 60)} min</IonCol>
                <IonCol size='1' style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{item.clienteNomeInterno || ""}</IonCol>
              </IonRow>
            )
          })}

        </IonGrid>
      </IonContent>
    </IonPage >
  );

};
export default Monitor;