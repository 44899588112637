import { IonBackButton, IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonChip, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonItem, IonLabel, IonModal, IonPage, IonRefresher, IonRefresherContent, IonRow, IonSelect, IonSelectOption, IonTitle, IonToolbar, useIonViewDidEnter } from '@ionic/react';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import './Painel.css';
import { calendar, grid, storefront } from 'ionicons/icons';
import OperacoesFiltroLojas from '../Operacoes/OperacoesFiltroLojas';
import { urlBase } from '../../../../../Utils/Services';
import dayjs from 'dayjs';
import { Doughnut, Line } from 'react-chartjs-2';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ptBR from 'date-fns/locale/pt-BR';
import ChartDataLabels from 'chartjs-plugin-datalabels';

const Painel: React.FC = () => {

  const history = useHistory();

  const [stateLojas, setStateLojas] = useState<string[]>(JSON.parse(window.localStorage.getItem("lojas") || "[]").map((valor: any) => (valor.clienteLojaId)));
  //const [stateLojas, setStateLojas] = useState<string[]>([]);

  const [stateDashboard, setStateDashboard] = useState<{}[]>([]);
  const [stateData, setStateData] = useState<Date>(new Date());
  const [showModalFiltroLojas, setShowModalFiltroLojas] = useState(false);
  const [stateColunas, setStateColunas] = useState<string>('3'); // default de 4 colunas por linha

  const options: any = {
    responsive: true,
    rotation: 270, // start angle in degrees
    circumference: 180, // sweep angle in degrees
    plugins: {
      legend: {
        display: false,

      },
      title: {
        display: true,
        text: 'Operações por tempo'
      },
      datalabels: {
        display: function (value: any) { return value.dataset.data[value.dataIndex] == 0 ? false : true; }, //'auto',
        color: 'black',
        anchor: 'center',
        align: 'center',
        font: {
          weight: 'bold',
          size: '10px'
        }

      }
    },

  };

  function optionsLine2(varMax: any) {
    return {
      plugins: {
        legend: { display: false },
        title: {
          display: true,
          text: 'Operações Iniciadas vs Concluídas',
          font: {
            size: 10
          }
        }
      },
      scales: {
        x: {
          grid: {
            display: false
          }
        },
        y: {
          display: true,
          position: 'left' as const,
          beginAtZero: true,
          min: 0,
          max: varMax + 2,
          ticks: {
            stepSize: 1
          }
        },
        y1: {
          display: false,
          beginAtZero: true,
          min: 0,
          max: varMax + 2,
          ticks: {
            stepSize: 1
          },
          grid: {
            drawOnChartArea: false,
          },
        },
        y2: {
          type: 'linear' as const,
          display: false,
          position: 'left' as const,
          grid: {
            drawOnChartArea: false,
          },
          beginAtZero: true,
          min: 0,
          max: varMax + 2,
          ticks: {
            stepSize: 1
          }
        }
      }
    };
  }

  function optionsLineNotas(varMax: any) {
    return {
      plugins: {
        legend: { display: false },
        title: {
          display: true,
          text: 'Notas Entregues vs Concluídas',
          font: {
            size: 10
          }
        }
      },
      scales: {
        x: {
          grid: {
            display: false
          }
        },
        y: {
          display: true,
          position: 'left' as const,
          beginAtZero: false,
          min: 0,
          max: varMax + 2,
          ticks: {
            stepSize: 1
          }
        },
        y1: {
          display: false,
          beginAtZero: true,
          min: 0,
          max: varMax + 2,
          ticks: {
            stepSize: 1
          }
        }
      }
    }
  }

  useIonViewDidEnter(() => {
    //CarregarDashboardV2();
    setShowModalFiltroLojas(true);
  });

  async function CarregarDashboard(lojas?: any, dateParam?: Date) {
    let lojasArray: any[] = [];

    let lojasCarregadas = (lojas || stateLojas).map(async (value: any) => {
      let paramFiltro = "Concluido";
      let paramLojas = Buffer.from(JSON.stringify(value)).toString('base64');
      let paramData
      if (dateParam) {
        paramData = dayjs(dateParam).format('YYYY-MM-DD');;
      }
      else {
        paramData = dayjs(stateData).format('YYYY-MM-DD');;
      }
      let paramPeriodo = "diario";

      let retorno: any = await BuscarOperacoes(paramFiltro, paramLojas, paramData, paramPeriodo);
      lojasArray.push(retorno);
      let retorno2: any = await BuscarOperacoes("Em", paramLojas, paramData, paramPeriodo);
      lojasArray.push(retorno2);
    });

    Promise.all(lojasCarregadas).then(() => setStateDashboard(lojasArray));
  }

  async function CarregarDashboardV2(lojas?: any, dateParam?: Date) {
    let lojasArray: any[] = [];

    let lojasCarregadas = (lojas || stateLojas).map(async (value: any) => {
      let paramFiltro = "Concluido";
      let paramLojas = Buffer.from(JSON.stringify(value)).toString('base64');
      let paramData
      if (dateParam) {
        paramData = dayjs(dateParam).format('YYYY-MM-DD');;
      }
      else {
        paramData = dayjs(stateData).format('YYYY-MM-DD');;
      }
      let paramPeriodo = "diario";

      let retorno: any = await BuscarOperacoesV2(paramFiltro, paramLojas, paramData, paramPeriodo);
      let responsePayload = await retorno.json();

      let retorno2: any = await BuscarOperacoesV2("Em", paramLojas, paramData, paramPeriodo);
      let responsePayload2 = await retorno2.json();

      let arrayConcatenado = (responsePayload.Items || []).concat(responsePayload2.Items || []);

      lojasArray.push(await TrataResultado(arrayConcatenado));
    });

    Promise.all(lojasCarregadas).then(() => setStateDashboard(lojasArray));
  }

  async function TrataResultado(items: any) {

    let varNomeLoja = "";
    let varAguardando = 0;
    let varLiberadas = 0;
    let varEncerradas = 0;
    let varTotal = 0;
    let varTempoTotal = 0;
    let varTempoMedio = 0;
    let varEncerradasAte60min = 0;
    let varEncerradasAte120min = 0;
    let varEncerradasAcima120min = 0;
    let varHorariosAbertura = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    let varHorariosEncerramento = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    let varHorariosOperacoesAbertas = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    let varAvaliacoes = 0;
    let varSomaAvaliacoes = 0;
    let varTotalNotas = 0;
    let varHorariosAberturaNotas = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    let varHorariosEncerramentoNotas = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    let varTempoTotalPrimeiraReacao = 0;
    let varTempoMedioPrimeiraReacao = 0;

    let teste = items.map((item: any) => {
      let dataInicio = dayjs(item.dataInicio);
      let dataFim = dayjs(item.dataEncerramento);
      let dataPrimeiraReacao;
      if (item.anotacoes && item.anotacoes[1] && item.anotacoes[1].data) {
        dataPrimeiraReacao = dayjs(item.anotacoes[1].data);
      }
      else {
        dataPrimeiraReacao = dataInicio;
      }
      let dateDiff = dataFim.diff(dataInicio, "minutes");
      if (dateDiff > 480) return;
      let dateDiffPrimeiraReacao = dataPrimeiraReacao.diff(dataInicio, "minutes");

      varNomeLoja = item.clienteNomeInterno || item.clienteNome;
      if (!item.dataEncerramento) varAguardando += 1;
      if (!item.dataEncerramento && item.liberado) varLiberadas += 1;
      if (item.dataEncerramento) varEncerradas += 1;
      varTotal += 1;
      varTempoTotal += dateDiff;
      varTempoMedio = Math.trunc(varTempoTotal / varTotal);
      varTempoTotalPrimeiraReacao += dateDiffPrimeiraReacao;
      varTempoMedioPrimeiraReacao = Math.trunc(varTempoTotalPrimeiraReacao / varTotal);
      if (dateDiff <= 60) varEncerradasAte60min += 1;
      if (dateDiff > 60 && dateDiff <= 120) varEncerradasAte120min += 1;
      if (dateDiff > 120) varEncerradasAcima120min += 1;
      varHorariosAbertura[dayjs(item.dataInicio).hour()] = varHorariosAbertura[dayjs(item.dataInicio).hour()] + 1;
      if (item.dataEncerramento) varHorariosEncerramento[dayjs(item.dataEncerramento).hour()] = varHorariosEncerramento[dayjs(item.dataEncerramento).hour()] + 1;
      if (item.avaliacao && item.avaliacao != '0') {
        varAvaliacoes += 1;
        varSomaAvaliacoes += parseInt(item.avaliacao);
      }
      varTotalNotas += item.notasAdicionais.length || 1;
      varHorariosAberturaNotas[dayjs(item.dataInicio).hour()] = varHorariosAberturaNotas[dayjs(item.dataInicio).hour()] + item.notasAdicionais.length || 1;
      if (item.dataEncerramento) varHorariosEncerramentoNotas[dayjs(item.dataEncerramento).hour()] = varHorariosEncerramentoNotas[dayjs(item.dataEncerramento).hour()] + item.notasAdicionais.length || 1;

    });

    let varHorariosAberturaAcumulado = varHorariosAbertura.map((sum => (value: number) => sum += value)(0));
    let varHorariosEncerramentoAcumulado = varHorariosEncerramento.map((sum => (value: number) => sum += value)(0));

    let varDashLoja =
    {
      nomeLoja: varNomeLoja,
      arguardando: varAguardando,
      liberadas: varLiberadas,
      encerradas: varEncerradas,
      total: varTotal,
      tempoTotal: varTempoTotal,
      tempoMedio: varTempoMedio,
      encerradasAte60min: varEncerradasAte60min,
      encerradasAte120min: varEncerradasAte120min,
      encerradasAcima120min: varEncerradasAcima120min,
      horariosAbertura: varHorariosAbertura,
      horariosEncerramento: varHorariosEncerramento,
      avaliacoes: varAvaliacoes,
      somaAvaliacoes: varSomaAvaliacoes,
      mediaAvaliacoes: varSomaAvaliacoes / varAvaliacoes || 0,
      totalNotas: varTotalNotas,
      horariosAberturaNotas: varHorariosAberturaNotas,
      horariosEncerramentoNotas: varHorariosEncerramentoNotas,
      tempoMedioPrimeiraReacao: varTempoMedioPrimeiraReacao,
      horariosOperacoesAbertas: varHorariosOperacoesAbertas.map((value: any, index: number) => { return varHorariosAberturaAcumulado[index] - varHorariosEncerramentoAcumulado[index] }),
      horariosAberturaAcumulado: varHorariosAberturaAcumulado
    }

    return varDashLoja;
  }

  async function BuscarOperacoesV2(paramFiltro: any, paramLojas: any, paramData: any, paramPeriodo: string) {

    let fecthOptions: any = {
      method: urlBase.method,
      headers: [
        ["token", localStorage.getItem("token") || ""]
      ],
      credentials: 'include'
    };

    return await fetch(urlBase.url + "/operacoes?filtro=" + paramFiltro + "&lojas=" + paramLojas + "&data=" + paramData + "&periodo=" + paramPeriodo, fecthOptions);
  }

  async function BuscarOperacoes(paramFiltro: any, paramLojas: any, paramData: any, paramPeriodo: string) {

    let fecthOptions: any = {
      method: urlBase.method,
      headers: [
        ["token", localStorage.getItem("token") || ""]
      ],
      credentials: 'include'
    };

    let response = await fetch(urlBase.url + "/operacoes?filtro=" + paramFiltro + "&lojas=" + paramLojas + "&data=" + paramData + "&periodo=" + paramPeriodo, fecthOptions);
    if (response.status != 200) {
      // dar mensagem de erro
    }
    else {
      let responsePayload = await response.json();

      let varNomeLoja = "";
      let varAguardando = 0;
      let varLiberadas = 0;
      let varEncerradas = 0;
      let varTotal = 0;
      let varTempoTotal = 0;
      let varTempoMedio = 0;
      let varEncerradasAte45min = 0;
      let varEncerradasAte90min = 0;
      let varEncerradasAte180min = 0;
      let varEncerradasAcima180min = 0;
      let varHorariosAbertura = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      let varHorariosEncerramento = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      let varHorariosOperacoesAbertas = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      let varAvaliacoes = 0;
      let varSomaAvaliacoes = 0;
      let varTotalNotas = 0;
      let varHorariosAberturaNotas = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      let varHorariosEncerramentoNotas = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      let varTempoTotalPrimeiraReacao = 0;
      let varTempoMedioPrimeiraReacao = 0;

      let teste = responsePayload.Items.map((item: any) => {
        let dataInicio = dayjs(item.dataInicio);
        let dataFim = dayjs(item.dataEncerramento);
        let dataPrimeiraReacao;
        if (item.anotacoes && item.anotacoes[1] && item.anotacoes[1].data) {
          dataPrimeiraReacao = dayjs(item.anotacoes[1].data);
        }
        else {
          dataPrimeiraReacao = dataInicio;
        }
        let dateDiff = dataFim.diff(dataInicio, "minutes");
        if (dateDiff > 1440) return;
        let dateDiffPrimeiraReacao = dataPrimeiraReacao.diff(dataInicio, "minutes");

        varNomeLoja = item.clienteNomeInterno || item.clienteNome;
        if (!item.dataEncerramento) varAguardando += 1;
        if (!item.dataEncerramento && item.liberado) varLiberadas += 1;
        if (item.dataEncerramento) varEncerradas += 1;
        varTotal += 1;
        varTempoTotal += dateDiff;
        varTempoMedio = Math.trunc(varTempoTotal / varTotal);
        varTempoTotalPrimeiraReacao += dateDiffPrimeiraReacao;
        varTempoMedioPrimeiraReacao = Math.trunc(varTempoTotalPrimeiraReacao / varTotal);
        if (dateDiff <= 45) varEncerradasAte45min += 1;
        if (dateDiff > 45 && dateDiff <= 90) varEncerradasAte90min += 1;
        if (dateDiff > 90 && dateDiff <= 180) varEncerradasAte180min += 1;
        if (dateDiff > 180) varEncerradasAcima180min += 1;
        varHorariosAbertura[dayjs(item.dataInicio).hour()] = varHorariosAbertura[dayjs(item.dataInicio).hour()] + 1;
        if (item.dataEncerramento) varHorariosEncerramento[dayjs(item.dataEncerramento).hour()] = varHorariosEncerramento[dayjs(item.dataEncerramento).hour()] + 1;
        if (item.avaliacao != '0') {
          varAvaliacoes += 1;
          varSomaAvaliacoes += parseInt(item.avaliacao);
        }
        varTotalNotas += item.notasAdicionais.length || 1;
        varHorariosAberturaNotas[dayjs(item.dataInicio).hour()] = varHorariosAberturaNotas[dayjs(item.dataInicio).hour()] + item.notasAdicionais.length || 1;
        if (item.dataEncerramento) varHorariosEncerramentoNotas[dayjs(item.dataEncerramento).hour()] = varHorariosEncerramentoNotas[dayjs(item.dataEncerramento).hour()] + item.notasAdicionais.length || 1;

      });

      let varHorariosAberturaAcumulado = varHorariosAbertura.map((sum => (value: number) => sum += value)(0));
      let varHorariosEncerramentoAcumulado = varHorariosEncerramento.map((sum => (value: number) => sum += value)(0));

      let varDashLoja =
      {
        nomeLoja: varNomeLoja,
        arguardando: varAguardando,
        liberadas: varLiberadas,
        encerradas: varEncerradas,
        total: varTotal,
        tempoTotal: varTempoTotal,
        tempoMedio: varTempoMedio,
        encerradasAte45min: varEncerradasAte45min,
        encerradasAte90min: varEncerradasAte90min,
        encerradasAte180min: varEncerradasAte180min,
        encerradasAcima180min: varEncerradasAcima180min,
        horariosAbertura: varHorariosAbertura,
        horariosEncerramento: varHorariosEncerramento,
        avaliacoes: varAvaliacoes,
        somaAvaliacoes: varSomaAvaliacoes,
        mediaAvaliacoes: varSomaAvaliacoes / varAvaliacoes || 0,
        totalNotas: varTotalNotas,
        horariosAberturaNotas: varHorariosAberturaNotas,
        horariosEncerramentoNotas: varHorariosEncerramentoNotas,
        tempoMedioPrimeiraReacao: varTempoMedioPrimeiraReacao,
        horariosOperacoesAbertas: varHorariosOperacoesAbertas.map((value: any, index: number) => { return varHorariosAberturaAcumulado[index] - varHorariosEncerramentoAcumulado[index] }),
        horariosAberturaAcumulado: varHorariosAberturaAcumulado
      }

      return varDashLoja;
    }
  }

  return (
    <IonPage class="painel">
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot='start'>
            <IonBackButton></IonBackButton>
          </IonButtons>
          <IonTitle>Painel</IonTitle>
          <IonChip slot='end' outline style={{ display: 'block', margin: 0, paddingLeft: 0, paddingRight: 0, height: '46px', borderRadius: '0px', border: 'none' }}>
            <div style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'bold' }}>Exibição</div>
            <div>
              <IonChip outline style={{ display: 'block', margin: 0, padding: 0, border: 'none', height: '46px', borderRadius: '0px' }}>
                <IonSelect style={{ margin: 0, padding: 0 }} placeholder="Colunas" value={stateColunas} onIonChange={(e: any) => setStateColunas(e.detail.value)}>
                  <IonSelectOption value="12">1 coluna</IonSelectOption>
                  <IonSelectOption value="6">2 colunas</IonSelectOption>
                  <IonSelectOption value="4">3 colunas</IonSelectOption>
                  <IonSelectOption value="3">4 colunas</IonSelectOption>
                  <IonSelectOption value="2">6 colunas</IonSelectOption>
                </IonSelect>
              </IonChip>
            </div>
          </IonChip>
          <IonButtons slot="end">
            <IonButton hidden={(window.localStorage.getItem("lojas") && JSON.parse(window.localStorage.getItem("lojas") || "").length > 0) ? false : true} onClick={() => setShowModalFiltroLojas(true)} >
              <IonIcon slot="icon-only" icon={storefront} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonRefresher slot="fixed" onIonRefresh={() => { }}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
        <div>
          <div style={{ position: 'relative', margin: 'auto', top: 0, bottom: 0, left: 0, right: 0, width: 'max-content' }}>
            <IonChip outline style={{ background: 'white', paddingLeft: 0, paddingRight: 0, height: '46px', borderRadius: '32px' }}>
              <IonChip outline style={{ background: 'white', border: 'none', height: '46px', borderRadius: '32px' }}>
                <div>
                  <DatePicker
                    onFocus={e => e.target.blur()}
                    dateFormat="dd/MM/yyyy"
                    locale={ptBR} portalId='paginaPainel'
                    selected={stateData}
                    onChange={(date) => {
                      let dataSelecionada = (date || new Date());
                      setStateData(dataSelecionada);
                      CarregarDashboardV2(stateLojas, dataSelecionada);
                    }}
                  />
                </div>
                <IonIcon icon={calendar} ></IonIcon>
              </IonChip>
            </IonChip>
          </div>
        </div>
        <IonModal isOpen={showModalFiltroLojas} onDidDismiss={() => setShowModalFiltroLojas(false)}>
          <OperacoesFiltroLojas lojas={stateLojas} setLojas={setStateLojas} setShowModalFiltroLojas={setShowModalFiltroLojas} updateDataFunction={CarregarDashboardV2} />
        </IonModal>
        <IonGrid>
          <IonRow>
            {stateDashboard.sort((a: any, b: any) => ((a.nomeLoja || "").toUpperCase() > (b.nomeLoja || "").toUpperCase()) ? 1 : -1).map((item: any) => {
              return (
                <IonCol size={stateColunas}>
                  <IonCard>
                    <IonCardHeader><IonLabel>{item?.nomeLoja || ""}</IonLabel></IonCardHeader>
                    <IonGrid>
                      <IonRow>
                        <IonCol size='6' style={{ maxWidth: '200px', textAlign: 'center' }}>
                          <div style={{ maxWidth: '200px', margin: '0 auto' }}>
                            <Doughnut data={{
                              labels: ['< 60min', '< 120min', '> 120min'],
                              datasets: [
                                {
                                  label: 'quantidade',
                                  data: [item.encerradasAte60min, item.encerradasAte120min, item.encerradasAcima120min],
                                  backgroundColor: [

                                    'rgba(0, 255, 0, 1)',
                                    'rgba(255, 255, 0, 1)',
                                    'rgba(255, 0, 255, 1)',
                                    'rgba(255, 0, 0, 1)',
                                    'rgba(249, 115, 14, 1)',

                                  ],
                                  borderColor: [
                                    'rgba(0, 0, 0, 0.3)',
                                    'rgba(0, 0, 0, 0.3)',
                                    'rgba(0, 0, 0, 0.3)'
                                  ],
                                  borderWidth: 1,
                                },
                              ],
                            }}
                              options={options}
                              plugins={[ChartDataLabels] as any}
                            />
                          </div>
                        </IonCol>
                        <IonCol size='6'>
                          <IonCardContent>
                            <IonItem class="ion-no-padding">
                              <IonLabel>arguardando</IonLabel>{item.arguardando}
                            </IonItem>
                            <IonItem class="ion-no-padding">
                              <IonLabel>liberadas</IonLabel>{item.liberadas}
                            </IonItem>
                            <IonItem class="ion-no-padding">
                              <IonLabel>encerradas</IonLabel>{item.encerradas}
                            </IonItem>
                            <IonItem class="ion-no-padding">
                              <IonLabel>total</IonLabel>{item.total}
                            </IonItem>
                            <IonItem class="ion-no-padding">
                              <IonLabel>notas</IonLabel>{item.totalNotas}
                            </IonItem>
                            <IonItem class="ion-no-padding">
                              <IonLabel>tempoTotal</IonLabel>{item.tempoTotal}
                            </IonItem>
                            <IonItem class="ion-no-padding">
                              <IonLabel>tempoMedio</IonLabel>{item.tempoMedio}
                            </IonItem>
                            <IonItem class="ion-no-padding">
                              <IonLabel>primeiraReacao</IonLabel>{item.tempoMedioPrimeiraReacao}
                            </IonItem>
                            <IonItem class="ion-no-padding">
                              <IonLabel>avaliações</IonLabel>{item.avaliacoes}
                            </IonItem>
                            <IonItem class="ion-no-padding">
                              <IonLabel>nota</IonLabel>{item.mediaAvaliacoes.toFixed(2)}
                            </IonItem>
                          </IonCardContent>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                    <Line
                      data={{
                        labels: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23],
                        datasets: [

                          {
                            label: 'Em andamento',
                            data: item.horariosOperacoesAbertas,
                            fill: false,
                            tension: 0,
                            borderColor: 'rgb(255,255,0)',
                            backgroundColor: 'rgb(255,255,0,0.5)',
                            yAxisID: 'y2'
                          },
                          {
                            label: 'Fechadas',
                            data: item.horariosEncerramento.map((sum => (value: number) => sum += value)(0)),
                            fill: true,
                            tension: 0,
                            borderColor: 'rgb(0,255,0)',
                            backgroundColor: 'rgb(0,255,0,0.5)',
                            yAxisID: 'y1'
                          },
                          {
                            label: 'Abertas',
                            data: item.horariosAbertura.map((sum => (value: number) => sum += value)(0)),
                            fill: true,
                            tension: 0,
                            borderColor: 'rgb(0,0,0)',
                            backgroundColor: 'rgb(0,0,0,0.5)',
                            yAxisID: 'y',

                          }
                        ]
                      }}
                      options={optionsLine2(Math.max(...item.horariosAberturaAcumulado))} />
                    <Line
                      data={{
                        labels: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23],
                        datasets: [

                          {
                            label: 'fechamento',
                            data: item.horariosEncerramentoNotas.map((sum => (value: number) => sum += value)(0)),
                            fill: true,
                            tension: 0,
                            borderColor: 'rgb(0,255,0)',
                            backgroundColor: 'rgb(0,255,0,0.5)',
                            yAxisID: 'y1'
                          },
                          {
                            label: 'abertura',
                            data: item.horariosAberturaNotas.map((sum => (value: number) => sum += value)(0)),
                            fill: true,
                            tension: 0,
                            borderColor: 'rgb(0,0,0)',
                            backgroundColor: 'rgb(0,0,0,0.5)',
                            yAxisID: 'y',

                          }
                        ]
                      }}
                      options={optionsLineNotas(Math.max(...item.horariosAberturaNotas.map((sum => (value: number) => sum += value)(0))))} />
                  </IonCard>
                </IonCol>
              )
            })}
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage >
  );

};
export default Painel;