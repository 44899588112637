import { IonBackButton, IonButton, IonButtons, IonContent, IonFooter, IonHeader, IonIcon, IonItem, IonLabel, IonList, IonTitle, IonToast, IonToggle, IonToolbar } from '@ionic/react';
import { arrowBack } from 'ionicons/icons';
import React from 'react';

class OperacoesFiltroLojas extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            lojas: [...this.props.lojas],
            showToast: false
        }
    }

    changeToggle(checked: boolean, valor: string) {
        if (checked) {
            this.state.lojas.push(valor);
            this.setState({ lojas: [...this.state.lojas] });
        }
        else {
            //console.log(this.props.lojas.filter((loja:any)=>loja != valor));
            this.setState({ lojas: [...this.state.lojas.filter((loja: any) => loja != valor)] });
        }
    }

    render() {
        return (
            <>
                <IonHeader>
                    <IonToolbar color='primary'>
                        <IonButtons slot='start'>
                            <IonButton onClick={() => this.props.setShowModalFiltroLojas(false)}>
                                <IonIcon icon={arrowBack}></IonIcon>
                            </IonButton>
                        </IonButtons>
                        <IonTitle>
                            <IonLabel className="ion-text-wrap">Filtro de Lojas</IonLabel>
                        </IonTitle>
                        <IonButtons slot='end'>
                            <IonButton onClick={() => { this.setState({ lojas: [] }); }}>
                                Desmarcar todos
                            </IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>
                <IonContent>
                    <IonList>
                        {JSON.parse(window.localStorage.getItem("lojas") || "[]").map((valor: any) => (
                            <IonItem lines='full'>
                                <IonLabel>{valor.clienteLojaNome}</IonLabel>
                                <IonToggle value={valor.clienteLojaId} slot='start' color="primary" checked={this.state.lojas.includes(valor.clienteLojaId)} onIonChange={e => this.changeToggle(e.detail.checked, e.detail.value)} />
                            </IonItem>

                        ))}
                    </IonList>
                    <br />
                    <IonToast
                        isOpen={this.state.showToast}
                        position="middle" //top, bottom, middle
                        onDidDismiss={() => this.setState({ showToast: false })}
                        message={'Selecione ao menos uma loja'}
                        duration={2000}
                    />
                </IonContent>
                <IonFooter>
                    <IonButton expand='full' onClick={
                        () => {
                            if (this.state.lojas.length > 0) {
                                this.props.setLojas([...this.state.lojas]);
                                if (this.props.updateDataFunction) this.props.updateDataFunction(this.state.lojas);
                                this.props.setShowModalFiltroLojas(false);
                            }
                            else {
                                this.setState({ showToast: true });
                            }
                        }
                    }>Filtrar</IonButton>
                </IonFooter>
            </>);
    }
}

export default OperacoesFiltroLojas;

