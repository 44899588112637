import '@capacitor-community/http';
import { IonAlert, IonBackButton, IonButton, IonButtons, IonContent, IonFooter, IonHeader, IonItem, IonLabel, IonList, IonLoading, IonPage, IonSelect, IonSelectOption, IonTextarea, IonTitle, IonToast, IonToolbar, useIonViewDidEnter } from '@ionic/react';
import { arrowBack } from 'ionicons/icons';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { clienteAlterar, clienteCarregar, requestService } from '../../../../../Utils/Services';
import './ContaAlt.css';

const ContaAlt: React.FC = () => {

  const history = useHistory();

  const [showToast2, setShowToast2] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [textToast, setTextToast] = useState("");

  const [instrucoes, setInstrucoes] = useState("");
  const [chamarServico, setChamarServico] = useState(true);
  const [showLoading, setShowLoading] = useState(true);
  const [showSucesso, setShowSucesso] = useState(false);
  const [lojas, setLojas] = useState<string>(JSON.parse(window.localStorage.getItem("lojas") || "[]")[0]?.clienteLojaId || "");

  useIonViewDidEnter(() => {
    document.getElementsByTagName("ion-tab-bar")[0].style.display = "none";

  });

  function carregarConta(loja?: any, autorizado?: any) {

    const consultar = async () => {

      let id = window.localStorage.getItem("cliente") || '';

      requestService(clienteCarregar.url.replace("{id}", id).concat("?filtro=", Buffer.from(loja || lojas).toString("base64")), {
        method: clienteCarregar.method,
        headers: [
          ["token", window.localStorage.getItem("token")]
        ],
        credentials: 'include'
      },
        history,
        (response: any) => {
          console.log(response);
          setInstrucoes(response?.Item?.instrucoesMotorista || '');
          setShowLoading(false);
        },
        (error: any) => {
          console.log(error);
          history.replace("/login/");
        });

    }

    if (chamarServico || autorizado) {
      setChamarServico(false);
      consultar();
    }
  }

  const cadastrar = () => {
    setShowToast2(true);
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonBackButton icon={arrowBack} text='' ></IonBackButton>
          </IonButtons>
          <IonTitle slot="start">Instruções ao Motorista</IonTitle>
          <IonSelect name='Lojas' placeholder='Selecione a loja' title='Lojas' value={lojas} multiple={false} cancelText="Cancel" okText="Ok" onIonChange={e => { setLojas(e.detail.value); carregarConta(e.detail.value, true); }} slot="end">
            {JSON.parse(window.localStorage.getItem("lojas") || "[]").map((valor: any) => (
              <IonSelectOption value={valor.clienteLojaId}>{valor.clienteLojaNome}</IonSelectOption>
            ))}
          </IonSelect>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {carregarConta()}
        <IonList>
          <IonItem lines="none">
            <IonLabel position="stacked">Instruções de carga e descarga</IonLabel>
            <IonTextarea style={{ "--background": "#eeeeee" }} rows={6} value={instrucoes} maxlength={250} placeholder="Instruções para orientar o motorista" onIonInput={(e: any) => setInstrucoes(e.target.value)}></IonTextarea>
          </IonItem>
        </IonList>
        <IonToast
          isOpen={showToast}
          position="middle" //top, bottom, middle
          onDidDismiss={() => setShowToast(false)}
          message={textToast}
          duration={6000}
        />

        <IonAlert isOpen={showToast2}
          onDidDismiss={() => setShowToast2(false)}
          header={'Alteração de cadastro'}
          message={'Você <strong>confirma</strong> a alteração?'}
          buttons={[
            {
              text: 'Cancelar',
              role: 'cancel',
            },
            {
              text: 'Confirmar',
              cssClass: 'primary',
              handler: () => {

                setShowLoading(true);

                const objectRequest = {
                  instrucoesMotorista: instrucoes
                }
                let id = window.localStorage.getItem("cliente") || '';

                if (window.localStorage.getItem("lojas") && JSON.parse(window.localStorage.getItem("lojas") || "").length > 0) {
                  id = lojas;
                }

                requestService(
                  clienteAlterar.url.replace("{id}", id.replaceAll("#", "%23")),
                  {
                    method: clienteAlterar.method,
                    body: JSON.stringify(objectRequest),
                    headers: [
                      ["token", window.localStorage.getItem("token")]
                    ],
                    credentials: 'include'
                  },
                  history,
                  (response: any) => {
                    console.log(response);
                    if (response) {
                      setTextToast("Alteração realizado com sucesso.");
                      setShowSucesso(true);

                      //history.replace("/cliente/conta");

                    } else if (response.status === 400) {
                      console.log(response);
                      setTextToast("Ocorreu um erro ao efetuar a alteração.");
                      setShowToast(true);

                    }
                    setShowLoading(false);
                  },
                  (error: any) => {
                    console.log(error);
                    setTextToast("Ocorreu um erro ao efetuar a alteração.");
                    setShowToast(true);
                    setShowLoading(false);
                  })
              }
            }
          ]}
        />

        <IonLoading
          cssClass='my-custom-class'
          isOpen={showLoading}
          onDidDismiss={() => { setShowLoading(false); }}
          spinner='circles'
        />

        <IonLoading
          cssClass='my-custom-class'
          isOpen={showSucesso}
          onDidDismiss={(e) => { setShowSucesso(false); history.replace("/cliente/conta"); }}
          message={'Sucesso!'}
          duration={1200}
          animated={false}
        />
      </IonContent>
      <IonFooter className="ion-no-border">
        <IonToolbar>
          <IonButton onClick={() => cadastrar()} expand="block" type="submit">Salvar</IonButton>
        </IonToolbar>
      </IonFooter>
    </IonPage>
  );
};

export default ContaAlt;
