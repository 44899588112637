import { IonContent, IonPage, IonButton, IonToast, IonLabel, IonInput, IonHeader, IonList, IonItem, IonToolbar, IonTitle, IonFooter, IonButtons, IonIcon, useIonViewWillEnter, IonBackButton, useIonViewWillLeave, useIonViewDidLeave, useIonViewDidEnter, IonLoading, IonAlert } from '@ionic/react';
import React from 'react';
import './TermosPolitica.css';
import '@capacitor-community/http';
import { arrowBack } from 'ionicons/icons';

const TermosPolitica: React.FC = () => {

  useIonViewDidEnter(() => {
    document.getElementsByTagName("ion-tab-bar")[0].style.display = "none";

  });

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonBackButton icon={arrowBack} text=''></IonBackButton>
          </IonButtons>
          <IonTitle>
            <IonLabel className="ion-text-wrap">Termos de Uso e política de privacidade</IonLabel>
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList>
          <IonItem lines='none'>
            <a target='_blank' href={'https://saidamax-plt-welcome.s3.us-east-2.amazonaws.com/termos/Termos_de_uso.pdf'}>Ler os termos de uso</a>
          </IonItem>
          <IonItem lines='none'>
            <a target='_blank' href={'https://saidamax-plt-welcome.s3.us-east-2.amazonaws.com/termos/Termos_de_uso.pdf'}>Ler a política de privacidade</a>
          </IonItem>
        </IonList>
      </IonContent>
    </IonPage>
  );
};

export default TermosPolitica;
