import { IonContent, IonPage, IonButton, IonLabel, IonInput, IonHeader, IonList, IonItem, IonToolbar, IonTitle, IonFooter, IonButtons, IonIcon, IonAlert, IonBackButton, IonLoading } from '@ionic/react';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import './InstrucoesCad.css';
import '@capacitor-community/http';
import { instrucoesCadastrar, requestService } from '../../../../../Utils/Services';
import { arrowBack } from 'ionicons/icons';

type MyModalProps = {
  closeAction: Function,
  clienteId?: string
}

const InstrucoesCad: React.FC<MyModalProps> = (props: MyModalProps) => {

  const history = useHistory();

  const [textToast, setTextToast] = useState("");
  const [instrucoes, setInstrucoes] = useState("");
  const [showIncluirUsuario, setShowIncluirUsuario] = useState(false);
  const [showSucesso, setShowSucesso] = useState(false);
  const [showErro, setShowErro] = useState(false);
  const [showLoading, setShowLoading] = useState(false);

  function cadastrar() {
    let mensagem = '';

    if (instrucoes.length === 0) {
      setTextToast("O campo Instruções é obrigatório.");
      setShowErro(true);
    }
    else {
      setShowIncluirUsuario(true);
    }
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonIcon slot="icon-only" icon={arrowBack} onClick={(e) => { props.closeAction(); }} />
          </IonButtons>
          <IonTitle>Inclusão de Benefício</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList>
          <IonItem>
            <IonLabel position="stacked">Benefício*</IonLabel>
            <IonInput placeholder="Digite o benefício" value={instrucoes} required maxlength={144} onIonInput={(e: any) => setInstrucoes(e.target.value)}></IonInput>
          </IonItem>
        </IonList>

        <IonAlert isOpen={showIncluirUsuario}
          onDidDismiss={() => setShowIncluirUsuario(false)}
          header={'Cadastro de benefício'}
          message={'Você <strong>confirma</strong> inclusão?'}
          buttons={[
            {
              text: 'Cancelar',
              role: 'cancel',
            },
            {
              text: 'Confirmar',
              cssClass: 'primary',
              handler: () => {

                setShowLoading(true);

                const objectRequest = {
                  instrucao: instrucoes
                }

                let id = window.localStorage.getItem("cliente") || '';

                if (props.clienteId && props.clienteId != ""){
                  id = props.clienteId;
                }

                requestService(instrucoesCadastrar.url.replace("{id}", id.replaceAll("#","%23")), {
                  method: instrucoesCadastrar.method,
                  body: JSON.stringify(objectRequest),
                  headers: [
                    ["token", window.localStorage.getItem("token")]
                  ],
                  credentials: 'include'
                },
                  history,
                  (response: any) => {
                    if (response.instrucoess) {
                      window.localStorage.setItem("carros", JSON.stringify(response.instrucoess));
                    }
                    setTextToast("Cadastro realizado com sucesso.");
                    setShowSucesso(true);
                    setShowLoading(false);
                  },
                  (error: any) => {
                    setTextToast(error.message);
                    setShowErro(true);
                    setShowLoading(false);
                  });
              }
            }
          ]}
        />

        <IonLoading
          cssClass='my-custom-class'
          isOpen={showLoading}
          onDidDismiss={() => { setShowLoading(false); }}
          message={'Incluindo...'}
        />

        <IonLoading
          cssClass='my-custom-class'
          isOpen={showSucesso}
          onDidDismiss={(e) => { setShowSucesso(false); setInstrucoes(""); props.closeAction(); }}
          message={'Sucesso!'}
          duration={1200}
          animated={false}
        />

        <IonAlert isOpen={showErro}
          onDidDismiss={() => setShowErro(false)}
          header={'Erro'}
          message={textToast}
          buttons={['OK']}
        />
      </IonContent>
      <IonFooter className="ion-no-border">
        <IonToolbar>
          <IonButton onClick={() => cadastrar()} expand="block" type="submit">Cadastrar</IonButton>
        </IonToolbar>
      </IonFooter>
    </IonPage>
  );
};

export default ({ closeAction, clienteId }: { closeAction: Function, clienteId?: string }) => (
  <InstrucoesCad closeAction={closeAction} clienteId={clienteId || ""}>
  </InstrucoesCad>
)
