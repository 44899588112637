import { IonContent, IonPage, IonHeader, IonLabel, IonButton, IonIcon, IonList, IonListHeader, IonItem, IonAvatar, IonModal, IonTextarea, useIonViewDidEnter, IonImg, IonFab, IonFabButton, IonGrid, IonRow, IonCol, IonFooter, IonToolbar, IonActionSheet, IonAlert, IonBackButton, IonButtons, IonTitle, IonSearchbar, IonBadge, IonNote, isPlatform } from '@ionic/react';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import "./Acompanhar.css";
import iconVerified from "./imgs/certificado.svg";
import iconTempo from "./imgs/tempo.svg";
import iconFlag from "./imgs/flag.svg";
import iconCompartilhar from "./imgs/compartilhar.svg";
import iconHorario from "./imgs/horario.svg";
import iconLocal from "./imgs/local.svg";
import iconNotaFiscal from "./imgs/notafiscal.svg";
import { addCircle, alertCircle, arrowBack, camera, cardOutline, carOutline, checkmarkCircle, close, closeOutline, cube, cubeOutline, eye, flag, flagOutline, hourglassOutline, informationCircle, informationCircleOutline, leafOutline, locationOutline, navigateOutline, options, personOutline, receiptOutline, star, starHalf, starOutline, timeOutline } from 'ionicons/icons';
import { urlBase, encerrarOperacao, requestService, incluiNotaFiscal } from '../../../../../Utils/Services';
import { promises } from 'dns';
import { OperationCanceledException } from 'typescript';
import { State } from 'ionicons/dist/types/stencil-public-runtime';
import { usePhotoGallery } from '../../../../../components/usePhotoGallery';
import { Plugins } from '@capacitor/core';
import BeautyStars from "beauty-stars";
import maps from './imgs/maps.png';
import waze from './imgs/waze.png';
import { dismiss } from '@ionic/core/dist/types/utils/overlays';
import { BarcodeScanner, BarcodeScannerOptions } from '@ionic-native/barcode-scanner';
import { BarcodeFormat, BrowserMultiFormatReader, DecodeHintType, Result } from '@zxing/library'
import { Toast } from '@capacitor/toast';

type Props = {
  estabelecimento: string;
  codigoBarras: string;
  operacaoid: string;
  onDismiss: () => void;
};

let stream2: any;
let arrayDevices: any[] = []

const Acompanhar: React.FC<Props> = (props: Props) => {

  const inputRef = useRef<any>(null);

  const { photos, takePhoto, loadPhotos } = usePhotoGallery(props.operacaoid);
  const history = useHistory<any>();
  const location = useLocation<any>();
  const [showBrowserCamera, setShowBrowserCamera] = useState(false);

  const [codigoBarras, setCodigoBarras] = React.useState<string>();
  const [abrirCompartilhar, setAbrirCompartilhar] = useState(false);
  const [abrirEncerrar, setAbrirEncerrar] = useState(false);
  const [abrirEncerrarDois, setAbrirEncerrarDois] = useState(false);
  const [dadosOperacao, setDadosOperacao] = useState<any>([]);
  const [operacaoCarregada, setOperacaoCarregada] = useState(false);
  const [horaInicio, setHoraInicio] = useState(new Date());
  const [horaFim, setHoraFim] = useState(new Date());
  const [temHoraFim, setTemHoraFim] = useState(false);
  const [tempoOperacao, setTempoOperacao] = useState("...");
  const [valorEstrela, setValorEstrela] = useState(0);
  const [textoEstrela, setTextoEstrela] = useState("Selecione");
  const [comentario, setComentario] = useState("");
  const [hideButton, setHideButton] = useState(true);
  const [showActionSheet, setShowActionSheet] = useState(false);
  const [alertConfirmar, setAlertConfirmar] = useState(false);
  const [notasAdicionais, setNotasAdicionais] = useState<any>([]);
  const [codeReader, setCodeReader] = React.useState(new BrowserMultiFormatReader());
  const [exibeTexto, setexibeTexto] = useState("");
  const [showCarregando, setShowCarregando] = useState(false);
  const [showModalCodigoBarrasDigitar, setShowModalCodigoBarrasDigitar] = useState(false);
  const [front, setFront] = useState(false);
  const [streamData, setStreamData] = useState<MediaStream>();
  const [alertaPreenchimento, setAlertaPreenchimento] = useState(false);
  const [botaoAtivo, setBotaoAtivo] = useState(true);

  useIonViewDidEnter(() => {
    document.getElementsByTagName("ion-tab-bar")[0].style.display = "none";

  });

  let params: any = {
    codigoBarras: "",
    estabelecimento: "",
    operacaoid: "",
  }
  params = useParams();

  if (location && location.state && location.state.operacaoid) {
    params.operacaoid = location.state.operacaoid;
  }


  if (props.operacaoid) {
    params = {
      codigoBarras: props.codigoBarras,
      estabelecimento: props.estabelecimento,
      operacaoid: props.operacaoid
    }
  }

  var estabelecimento = {
    nome: params.estabelecimento,
    parceiro: false,
    endereco: "Rua Selvin Ln, 1035, São Paulo, SP",
    horarios: [
      { dia: "segunda-feira", inicio: "8:00", fim: "16:00", aberto: true },
      { dia: "terça-feira", inicio: "8:00", fim: "16:00", aberto: true },
      { dia: "quarta-feira", inicio: "8:00", fim: "16:00", aberto: true },
      { dia: "quinta-feira", inicio: "8:00", fim: "16:00", aberto: true },
      { dia: "sexta-feira", inicio: "8:00", fim: "16:00", aberto: true },
      { dia: "sábado", inicio: "8:00", fim: "16:00", aberto: true },
      { dia: "domingo", inicio: "8:00", fim: "16:00", aberto: false },
    ],
    medias: {
      historico: "45 min",
      hoje: "1h 30min"
    }
  }

  function tempoCorrido(horario: Date, dataFim?: Date) {
    var g = Math.ceil(((dataFim ? dataFim.getTime() : Date.now()) - horario.getTime()) / 1000 / 60);
    var tempoApurado = Math.floor(g / 60).toString() + 'h' + ('0' + (g % 60).toString()).substr(-2) + 'min';
    return tempoApurado !== "0h00min" ? tempoApurado : "0h01min";
  }

  function carregarOperacao() {
    //console.log(params.operacaoid);
    if (params.operacaoid != "" && params.operacaoid != undefined) {
      if (!operacaoCarregada) {
        setOperacaoCarregada(true);

        var token: string = "";
        var tkn = localStorage.getItem("token");
        if (typeof (tkn) === "string") { token = tkn; }
        const getoperacao = async () => {
          const resp = await fetch(urlBase.url + "/operacoes/operacao%23" + params.operacaoid,
            {
              method: urlBase.method,
              headers: [
                ["token", token]
              ],
              credentials: 'include'
            });
          const operacao = await resp.json();
          //if (resp.status === 400 || resp.status === 401) history.replace("/login/");
          if (operacao.Item !== undefined) {
            setDadosOperacao(operacao);
            setHideButton(operacao.Item.sk.startsWith("usuarios#") ? false : false);
            setHoraInicio(new Date(operacao.Item.dataInicio));
            setTemHoraFim(operacao.Item.dataEncerramento ? true : false);
            setHoraFim(operacao.Item.dataEncerramento ? new Date(operacao.Item.dataEncerramento) : new Date());
            setTempoOperacao(tempoCorrido(new Date(operacao.Item.dataInicio), operacao.Item.dataEncerramento ? new Date(operacao.Item.dataEncerramento) : new Date()));
            setNotasAdicionais(operacao.Item.notasAdicionais || []);

            if (operacao.Item.photo) {


              let _photos: string[] = operacao.Item.photo;
              loadPhotos(_photos);

            }

          }

        }

        getoperacao();
      }
    }
    return "";
  }

  setInterval(() => setTempoOperacao(tempoCorrido(horaInicio)), 60000);

  function loadHorarios() {
    return estabelecimento.horarios.map((value, index) => {
      return (
        <div className="horario" key={index}>
          <IonLabel>{value.dia}</IonLabel>
          <IonLabel>{value.aberto ? value.inicio + " - " + value.fim : "Fechado"}</IonLabel>
        </div>);
    });

  }

  async function encerrar() {
    const realizarEncerramento = async () => {

      var token: string = "";
      var tkn = localStorage.getItem("token");
      if (typeof (tkn) === "string") { token = tkn; }

      const resp = await fetch(encerrarOperacao.url.replace("{id}", "operacao%23" + params.operacaoid),
        {
          method: encerrarOperacao.method,
          headers: [
            ["token", token]
          ],
          credentials: 'include'
        });

      const res = await resp.json();
      if (res.message) {
        //console.log(res);
        history.replace("/cliente/operacoes", { recarregar: true });
      }
    }
    await realizarEncerramento();
  }

  function encerrar2() {
    var token: string = "";
    var tkn = localStorage.getItem("token");
    if (typeof (tkn) === "string") { token = tkn; }

    return fetch(encerrarOperacao.url.replace("{id}", "operacao%23" + params.operacaoid),
      {
        method: encerrarOperacao.method,
        headers: [
          ["token", token]
        ],
        credentials: 'include'
      });
  }


  function adicionaZeroNaData(numero: any) {
    if (numero <= 9)
      return "0" + numero;
    else
      return numero;
  }

  function formatarData(data: Date) {
    return (adicionaZeroNaData(data.getDate().toString()) + "/" + (adicionaZeroNaData(data.getMonth() + 1).toString()) + "/" + data.getFullYear().toString().substr(2));
  }

  function formatarDataHora(data: Date) {
    return adicionaZeroNaData(data.getDate().toString()) + "/" + (adicionaZeroNaData(data.getMonth() + 1).toString()) + " " + ("0" + data.getHours().toString()).substr(-2) + ":" + ("0" + data.getMinutes().toString()).substr(-2);
  }

  function ajustaTextoEstrela(value: any) {
    if (value === 1) setTextoEstrela("Muito ruim");
    if (value === 2) setTextoEstrela("Ruim");
    if (value === 3) setTextoEstrela("Normal");
    if (value === 4) setTextoEstrela("Bom");
    if (value === 5) setTextoEstrela("Excelente");
  }

  const openScanner = async () => {
    if (isPlatform("mobileweb") || isPlatform("desktop")) {
      setShowBrowserCamera(true);
    }
    else {

      const opt: BarcodeScannerOptions = {
        formats: 'CODE_128',
        prompt: 'Escaneie o codigo de barras'
      }

      const data: any = await BarcodeScanner.scan(opt);

      if (data !== null) {
        setShowCarregando(true);
        registrarNotaFiscal(data.text);
      }
    }
  };

  function registrarNotaFiscal(valor: any) {

    if (valor === undefined || valor.replace(' ', '') === '') {
      setAlertaPreenchimento(true);
    }
    else {
      setShowCarregando(true);
      let objeto = { "notaFiscal": valor.toString().replace(/ /g, ""), "tipoOperacao": "Indefinida" };

      const registrar = () => {
        requestService(incluiNotaFiscal.url.replace("{id}", params.operacaoid.replace(/"#"/g, "%23")), {
          method: incluiNotaFiscal.method,
          headers: [
            ["token", window.localStorage.getItem("token")]
          ],
          body: JSON.stringify(objeto),
          credentials: 'include'
        },
          history,
          (response: any) => {
            let objeto = { "notaFiscal": valor.toString(), "tipoOperacao": "Indefinida" };
            let incremento = notasAdicionais;
            incremento.push(objeto);
            setNotasAdicionais(incremento);
            Toast.show({
              text: "Sucesso na inclusão da nota",
              duration: "long"
            });
            window.localStorage.setItem("reloadOperacoes", "sim");
            setShowBrowserCamera(false);
            setShowModalCodigoBarrasDigitar(false);
            setCodigoBarras('');
            setShowCarregando(false);
            //setexibeTexto(JSON.stringify(response));
          },
          (error: any) => {
            Toast.show({
              text: "Erro na inclusão da nota",
              duration: "long"
            });
            setShowBrowserCamera(false);
            setShowCarregando(false);
          });
      }

      registrar();
    }
  }

  function cancelarScan() {
    if (codeReader) {
      codeReader.stopAsyncDecode();
      codeReader.stopContinuousDecode();
    }

    console.log(streamData);
    if (streamData) {
      streamData.getVideoTracks().forEach((valor: MediaStreamTrack) => {
        console.log(valor);
        valor.stop();
      });
    }
  }

  function startCamera() {
    if (arrayDevices.length == 0) {
      console.log("loadDevices");
      navigator.mediaDevices
        .enumerateDevices()
        .then(gotDevices)
        .then(getStream)
        .catch((err) => { console.log(err); });
    } else {
      getStream();
    }
  }

  function getStream() {
    if (stream2) {
      stream2.getTracks().forEach(function (track: any) {
        console.log("getTracks");
        track.stop();
      });
    }

    //document.getElementById('flip-button').onclick = function() { setFront(!front); };

    const constraints = {
      audio: false,
      video: {
        width: 1024,
        height: 576,
        facingMode: (front ? "user" : "environment")
        //facingMode: { exact: "environment" }

        //deviceId: { exact: arrayDevices[selectedCamera] },
      },
    };

    navigator.mediaDevices
      .getUserMedia(constraints)
      .then(gotStream)
      .catch((err) => { console.log(err) });
  }

  function gotDevices(deviceInfos: any) {
    //console.log("getDevices");
    for (let i = 0; i !== deviceInfos.length; ++i) {
      const deviceInfo = deviceInfos[i];
      if (deviceInfo.kind === "videoinput") {
        arrayDevices.push(deviceInfo.deviceId);
      }
    }
  }

  function gotStream(stream: MediaStream) {
    setStreamData(stream);
    //console.log("gotStream");
    const videoElement = document.getElementById("video") as HTMLVideoElement;

    videoElement.srcObject = stream;
    codeReader.decodeFromStream(stream, videoElement, (result: any) => {
      if (result) {
        codeReader.stopAsyncDecode();
        codeReader.stopContinuousDecode();
        stream.getVideoTracks().forEach((valor: MediaStreamTrack) => {
          //console.log(valor);
          valor.stop();
        });
        registrarNotaFiscal(result.text.toString());
        //setShowBrowserCamera(false);
      } else {

      }
    }).catch((err) => { console.log(err) });

  }

  function space(str: any, after: any) {
    setAlertaPreenchimento(false);

    if (!str) {
      setCodigoBarras('');
      return false;
    }
    after = after || 4;
    var v = str.replace(/[^\dA-Z]/g, ''),
      reg = new RegExp(".{" + after + "}", "g");
    setCodigoBarras(
      v.replace(reg, function (a: any) {
        return a + ' ';
      })
    );
  };


  return (

    <IonPage className="Acompanhar">
      <IonHeader className="ion-no-border">
        <IonToolbar color="primary">
          {carregarOperacao()}
          <IonButtons slot="start">
            <IonButton onClick={() => { history.goBack();/*.replace("/cliente/operacoes", { recarregar: false }); */ }}>
              <IonIcon slot="icon-only" icon={arrowBack} />
            </IonButton>
          </IonButtons>
          <IonItem lines="none" color="primary" >
            <IonTitle color="dark" className="ion-text-wrap" style={{ "paddingLeft": "20px", "paddingTop": "0px", "paddingBottom": "0px", "textAlign": "left" }}>{dadosOperacao.Item ? dadosOperacao.Item.clienteNome : ""}</IonTitle>
            <IonBadge hidden={dadosOperacao.Item ? !dadosOperacao.Item.clienteParceiroSm : true} color="tertiary" slot="end" >parceiro</IonBadge>
          </IonItem>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen={false}>
        <IonModal isOpen={showCarregando} cssClass='my-custom-class aguarde'>
            <IonLabel className="helper">Aguarde, realizando operação</IonLabel>
          </IonModal>
        <IonModal isOpen={showBrowserCamera} onDidDismiss={() => { setShowBrowserCamera(false); cancelarScan(); }} cssClass='my-custom-class camera' onDidPresent={() => { startCamera() }}>
          <video hidden={false} style={{ display: "flex", flexDirection: "column" }} id="video" autoPlay muted playsInline></video>
          <IonLabel className="helper">Aponte a camera para o <b>código de barras</b> que será feita a leitura.</IonLabel>
          {/*
          <IonItem>
            <IonLabel hidden={false}>{notaLida}</IonLabel>
          </IonItem>
          */}
          <div className="actions" style={{ width: "100%", display: "flex", flexDirection: "column" }}>
            {/*<IonButton fill="clear" slot="end" color="tertiary" onClick={() => {registrarNotaFiscal("12345646545644252345324523452345432523452344324342654");}}>input</IonButton>*/}
            {/*<IonButton fill="solid" slot="end" color="tertiary" onClick={() => {cancelarScan();lerNotaFiscal();}}>Ler Chave de Acesso da Nota</IonButton>*/}
            <IonButton fill="solid" slot="end" color="tertiary" onClick={() => { setFront(!front); startCamera(); }}>Virar camera</IonButton>
            <IonButton fill="clear" slot="end" color="tertiary" onClick={() => { cancelarScan(); setShowBrowserCamera(false); }}>Cancelar</IonButton>
          </div>
        </IonModal>
        <IonModal isOpen={showModalCodigoBarrasDigitar} onDidPresent={() => { inputRef.current.setFocus(); }} onDidDismiss={() => {setShowModalCodigoBarrasDigitar(false);setCodigoBarras('');setAlertaPreenchimento(false);}} >
          <IonHeader>
            <IonToolbar color="primary">
              <IonTitle>Incluir Nota Fiscal</IonTitle>
              <IonButtons slot="start" onClick={() => { cancelarScan(); setShowModalCodigoBarrasDigitar(false); }}>
                <IonIcon slot="icon-only" icon={arrowBack}></IonIcon>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent class="ion-padding">
            <IonItem lines="none">
              <IonIcon hidden={!alertaPreenchimento} size="large" slot="start" src={alertCircle} color="danger" />
              <IonLabel hidden={!alertaPreenchimento} color="danger" style={{ "font-size": "14px" }} >Campo obrigatório</IonLabel>
            </IonItem>
            <IonItem lines="none">
              <IonLabel className="ion-text-wrap" position="stacked">Informe a <b>chave de acesso</b> (código de barras) na <b>nota</b>:</IonLabel>
              <IonTextarea ref={(ref) => inputRef.current = ref} rows={3} inputMode="numeric" maxlength={54} value={codigoBarras} placeholder="Utilize o leitor de código de barras ou digite" onIonChange={e => space(e.detail.value!, 4)}></IonTextarea>
            </IonItem>
          </IonContent>
          <IonFooter className="ion-no-border" >
            <IonToolbar>
              <IonButton expand="block" size='large' disabled={!botaoAtivo} onClick={() => { registrarNotaFiscal((codigoBarras||"").replace(/ /g, "")) }}>Registrar Nota!</IonButton>
              <IonButton expand="block" fill="clear" color="tertiary" onClick={() => { openScanner() }}>Utilizar câmera do celular</IonButton>
            </IonToolbar>
          </IonFooter>
        </IonModal>
        <div className="header">
          <div className="info">
            <IonIcon src={flagOutline}></IonIcon>
            <div>
              <IonLabel>Chegada</IonLabel>
              <IonLabel>{dadosOperacao.Item ? ("0" + new Date(dadosOperacao.Item.dataInicio).getHours().toString()).substr(-2) + ":" + ("0" +
                new Date(dadosOperacao.Item.dataInicio).getMinutes().toString()).substr(-2) : ""}</IonLabel>
            </div>
          </div>
          <div className="info">
            <IonIcon src={!temHoraFim ? hourglassOutline : flag}></IonIcon>
            <div hidden={temHoraFim}>
              <IonLabel>Aguardando</IonLabel>
              <IonLabel>{tempoOperacao}</IonLabel>
            </div>
            <div hidden={!temHoraFim}>
              <IonLabel>Término</IonLabel>
              <IonLabel>{dadosOperacao.Item ? ("0" + new Date(dadosOperacao.Item.dataEncerramento).getHours().toString()).substr(-2) + ":" + ("0" +
                new Date(dadosOperacao.Item.dataEncerramento).getMinutes().toString()).substr(-2) : ""}</IonLabel>
            </div>
          </div>
        </div>
        <IonList>
          <IonListHeader lines="full">
            <IonLabel>Operação</IonLabel>
          </IonListHeader>
          <IonItem lines="none">
            <IonIcon icon={cubeOutline} slot="start" />
            <div className="contentList">
              <h1>Tipo da operação</h1>
              <h2>{dadosOperacao.Item && dadosOperacao.Item.tipoOperacao ? dadosOperacao.Item.tipoOperacao : "não identificado"}</h2>
              <h2>{exibeTexto}</h2>
            </div>
          </IonItem>
          <IonItem lines="none">
            <IonIcon icon={informationCircleOutline} slot="start" />
            <div className="contentList">
              <h1>Status</h1>
              <h2>{dadosOperacao.Item ? dadosOperacao.Item.status : ""}</h2>
            </div>
          </IonItem>
          <IonItem lines="none">
            <IonIcon src={leafOutline} slot="start" />
            <div className="contentList">
              <h1>Tipo da carga</h1>
              <h2>{dadosOperacao.Item && dadosOperacao.Item.tipoCarga ? dadosOperacao.Item.tipoCarga : "não identificado"}</h2>
            </div>
          </IonItem>
          <IonItem lines="none">
            <IonIcon icon={timeOutline} slot="start" />
            <div className="contentList">
              <h1>Tempo de permanência</h1>
              <div className="horario">
                <IonLabel>Chegada</IonLabel>
                <IonLabel>{dadosOperacao.Item ? formatarDataHora(new Date(dadosOperacao.Item.dataInicio)) : ""}</IonLabel>
              </div>
              <div className="horario">
                <IonLabel>Término</IonLabel>
                <IonLabel>{dadosOperacao.Item && dadosOperacao.Item.dataEncerramento ? formatarDataHora(new Date(dadosOperacao.Item.dataEncerramento)) : ""}</IonLabel>
              </div>
              <div className="horario">
                <IonLabel>Duração</IonLabel>
                <IonLabel>{tempoOperacao}</IonLabel>
              </div>
            </div>
          </IonItem>

          <IonListHeader lines="full" style={{ "display": "block" }}>
            <IonLabel>Notas Fiscais</IonLabel>
            <IonItem hidden={temHoraFim} lines="none">
              <IonIcon src={addCircle} color="primary" onClick={() => { setShowModalCodigoBarrasDigitar(true);/*openScanner();*/ }}></IonIcon>
            </IonItem>
          </IonListHeader>
          <IonItem lines="none" hidden={dadosOperacao.Item ? (dadosOperacao.Item.operacaoChaveAcesso === "0" ? true : false) : false}>
            <IonIcon src={receiptOutline} slot="start" />
            <div className="contentList">
              <h1>Nota Fiscal</h1>
              <h2>{dadosOperacao.Item ? dadosOperacao.Item.operacaoChaveAcesso.substr(25, 9) : ""} </h2>
            </div>
            <IonIcon slot="end" color="primary" src={eye} onClick={() => { window.open("https://www.nfe.fazenda.gov.br/portal/consultaRecaptcha.aspx?tipoConsulta=completa&nfe=" + (dadosOperacao.Item ? dadosOperacao.Item.operacaoChaveAcesso : ""), '_blank') /* tipoConteudo=7PhJ+gAVw2g=& */ }} ></IonIcon>
          </IonItem>
          {notasAdicionais.map((notasValue: any, index: any) => (
            <IonItem lines="none">
              <IonIcon src={receiptOutline} slot="start" />
              <div className="contentList">
                <h1>Nota Fiscal</h1>
                <h2>{notasValue ? notasValue.notaFiscal.substr(25, 9) : ""} </h2>
              </div>
              <IonNote hidden={true} slot="end">{notasValue ? notasValue.tipoOperacao : ""}</IonNote>
              <IonIcon slot="end" color="primary" src={eye} onClick={() => { window.open("http://www.nfe.fazenda.gov.br/portal/consultaRecaptcha.aspx?tipoConsulta=completa&nfe=" + (notasValue ? notasValue.notaFiscal : ""), '_blank')  /*window.open("http://www.nfe.fazenda.gov.br/portal/consultaRecaptcha.aspx?tipoConteudo=XbSeqxE8pl8=&tipoConsulta=completa&nfe=" + (notasValue ? notasValue.notaFiscal : ""), '_blank')*/ }} ></IonIcon>
            </IonItem>
          ))}

          <IonListHeader lines="full">
            <IonLabel>Motorista</IonLabel>
          </IonListHeader>
          <IonItem lines="none">
            <IonIcon src={personOutline} slot="start" />
            <div className="contentList">
              <h1>Nome</h1>
              <h2>{dadosOperacao.Item && dadosOperacao.Item.motoristaNome ? dadosOperacao.Item.motoristaNome : "não identificado"}</h2>
            </div>
          </IonItem>
          <IonItem lines="none">
            <IonIcon src={cardOutline} slot="start" />
            <div className="contentList">
              <h1>Documento</h1>
              <h2>{dadosOperacao.Item && dadosOperacao.Item.motoristaDocumento ? dadosOperacao.Item.motoristaDocumento : "não identificado"}</h2>
            </div>
          </IonItem>
          <IonItem lines="none">
            <IonIcon src={carOutline} slot="start" />
            <div className="contentList">
              <h1>Veículo</h1>
              <h2>{dadosOperacao.Item && dadosOperacao.Item.veiculo ? dadosOperacao.Item.veiculo : "não identificado"}</h2>
            </div>
          </IonItem>
          <IonListHeader lines="full">
            <IonLabel>Local</IonLabel>
          </IonListHeader>
          <IonItem lines="none">
            <IonIcon src={navigateOutline} slot="start" />
            <div className="contentList">
              <h1>Endereço</h1>
              <h2>{dadosOperacao.Item ? dadosOperacao.Item.clienteEndereco : ""}</h2>
              <div style={{ paddingTop: "10px", display: "flex", columnGap: "20px", justifyContent: "left" }} >
                <a target='_blank' href={"https://www.google.com/maps/search/?api=1&query=" + (dadosOperacao.Item ? dadosOperacao.Item.clienteLatitude.concat(",").concat(dadosOperacao.Item.clienteLongitude).concat("&query_place_id=").concat(dadosOperacao.Item.clienteCodigo || "") : "")}><img src={maps} alt="maps" /></a>
                <a target='_blank' href={"https://waze.com/ul?ll=" + (dadosOperacao.Item ? dadosOperacao.Item.clienteLatitude.concat("%2C").concat(dadosOperacao.Item.clienteLongitude) : "") + "&navigate=yes"}><img src={waze} alt="waze" /></a>
              </div>
            </div>
          </IonItem>
          <IonItem lines="full" hidden={true}>
            <IonIcon src={iconHorario} slot="start" />
            <div className="contentList">
              <h1>Horários de Funcionamento</h1>
              {loadHorarios()}
            </div>
          </IonItem>
          <IonItem lines="full" hidden={true}>
            <IonIcon src={iconTempo} slot="start" />
            <div className="contentList">
              <h1>Tempos Médios de Carga/Descarga</h1>
              <div className="horario">
                <IonLabel>Histórico</IonLabel>
                <IonLabel>{estabelecimento.medias.historico}</IonLabel>
              </div>
              <div className="horario">
                <IonLabel>Hoje</IonLabel>
                <IonLabel>{estabelecimento.medias.hoje}</IonLabel>
              </div>
            </div>
          </IonItem>
          <IonListHeader hidden={false} lines="full">
            <IonLabel>Ocorrências</IonLabel>
            <IonItem hidden={temHoraFim} lines="none">
              <IonIcon src={addCircle} color="primary" onClick={async () => { await takePhoto(params.operacaoid).catch((e) => { console.log("cancelado"); }); }}></IonIcon>
            </IonItem>
          </IonListHeader>
          <IonItem hidden={false} lines="none">

            <IonGrid>
              <IonRow>
                {photos.map((photo, index) => (
                  <IonCol size="6" key={index}>
                    <div>
                      <a target='_blank' href={photo.webviewpath}>
                        <IonImg src={photo.webviewpath} />
                      </a>
                    </div>
                  </IonCol>
                ))}
              </IonRow>
            </IonGrid>

          </IonItem>
        </IonList>
        <IonActionSheet
          backdropDismiss={true}
          translucent={true}
          isOpen={showActionSheet}
          onDidDismiss={() => setShowActionSheet(false)}
          cssClass='my-custom-class'
          buttons={[{
            text: 'Encerrar Operação',
            icon: checkmarkCircle,
            handler: () => {
              setAlertConfirmar(true);
            }
          }, {
            text: 'Cancelar',
            icon: close,
            role: 'cancel',
            handler: () => {
              console.log('Cancel clicked');
            }
          }]}
        />
        <IonAlert isOpen={alertConfirmar}
          onDidDismiss={() => setAlertConfirmar(false)}
          header={'Encerrar operação'}
          message={'Realizar <strong>encerramento</strong>?'}
          buttons={[
            {
              text: 'Cancelar',
              role: 'cancel',
            },
            {
              text: 'Confirmar',
              cssClass: 'primary',
              handler: async () => {
                await encerrar2().then(res => {
                  //const res = await resp.json();
                  //if (res.message) {
                  console.log(res);
                  history.replace("/cliente/operacoes", { recarregar: true });
                }).catch(() => console.log("Erro na chamada"));
              }
            }
          ]}
        />
      </IonContent>
      <IonFooter className="ion-no-border" hidden={(hideButton || temHoraFim)} >
        <IonToolbar>
          <IonButton size="small" expand="block" onClick={() => setShowActionSheet(true)}>Ações</IonButton>
        </IonToolbar>
      </IonFooter>
    </IonPage>
  );


};

export default Acompanhar;