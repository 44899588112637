import '@capacitor-community/http';
import { IonAlert, IonButton, IonButtons, IonChip, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonList, IonRow, IonSelect, IonSelectOption, IonTitle, IonToolbar } from '@ionic/react';
import ptBR from 'date-fns/locale/pt-BR';
import { arrowBack, calendar, time } from 'ionicons/icons';
import React, { useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { useHistory } from 'react-router-dom';
import validator from 'validator';
import { cadastraAgendamento, requestService } from '../../../../../Utils/Services';
import './AgendamentosCad.css';

type MyModalProps = {
  agendamento: any;
  closeAction: Function;
  closeActionSimple: Function;
}

const AgendamentosCad: React.FC<MyModalProps> = (props: MyModalProps) => {

  const history = useHistory();

  const [textToast, setTextToast] = useState("");
  const [CNPJ, setCNPJ] = useState("");
  const [descricaoAgendamento, setDescricaoAgendamento] = useState("");
  const [numeroPedido, setNumeroPedido] = useState("");
  const [email, setEmail] = useState("");
  const [showIncluirAgendamento, setShowIncluirAgendamento] = useState(false);
  const [showSucesso, setShowSucesso] = useState(false);
  const [showErro, setShowErro] = useState(false);
  const [startDate, setStartDate] = useState<Date>();
  const [startTime, setStartTime] = useState(new Date(new Date().setHours(12, 0, 0, 0)));
  const [lojas, setLojas] = useState<string>("");

  const TAB_KEY_CODE = 9;
  const ENTER_KEY_CODE = 13;

  function cadastrar() {
    if (lojas == "") {
      setTextToast("Campo Loja é obrigatório.");
      setShowErro(true);
    }
    else if (startDate == undefined) {
      setTextToast("Campo Data é obrigatório.");
      setShowErro(true);
    }
    else if (descricaoAgendamento.trim().length == 0) {
      setTextToast("Campo Descrição é obrigatório.");
      setShowErro(true);
    }
    else if (CNPJ.length != 18) {
      setTextToast("Campo CNPJ é obrigatório.");
      setShowErro(true);
    }
    else if (email.trim() != "" && !validator.isEmail(email)) {
      setTextToast("Campo E-mail inválido.");
      setShowErro(true);
    }
    else {
      setShowIncluirAgendamento(true);
    }
  }

  function onChangeCNPJ(e: any) {
    e.persist();
    e.preventDefault();

    if (e.keyCode === ENTER_KEY_CODE || e.keyCode === TAB_KEY_CODE) {
      e.target.value = e.target.value.substring(0, 18);
      setCNPJ(e.target.value.substring(0, 18));
    }

    if (e.target.value.length > 18) {
      e.target.value = e.target.value.substring(0, 18);
      setCNPJ(e.target.value.substring(0, 18));
      return;
    }

    let val = e.target.value;
    var valor = val.replace(/\D+/g, '') // não deixa ser digitado nenhuma letra
      .replace(/(\d{2})(\d)/, '$1.$2') // captura 2 grupos de número o primeiro com 2 digitos e o segundo de com 3 digitos, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de número
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1/$2') // captura 2 grupos de número o primeiro e o segundo com 3 digitos, separados por /
      .replace(/(\d{4})(\d)/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1'); // captura os dois últimos 2 números, com um - antes dos dois números

    setCNPJ(valor);

  }

  return (
    <>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonIcon slot="icon-only" icon={arrowBack} onClick={() => props.closeActionSimple()} />
          </IonButtons>
          <IonTitle>Novo agendamento</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent id='agendamentoCadContent' className='agendamentoCadContent'>
        <div style={{ height: '30px' }}></div>
        <IonGrid style={{ position: 'relative', margin: 'auto', top: 0, bottom: 0, left: 0, right: 0, width: 'fit-content' }}>
          <IonRow >
            <IonCol >
              <IonList>
                <IonItem lines="none" >
                  <IonLabel position="fixed">Loja*</IonLabel>
                  <IonChip outline style={{ width: '100%', }}>

                    <IonSelect style={{ width: '100%' }} name='Lojas' placeholder='Loja' title='Lojas' value={lojas} multiple={false} cancelText="Cancel" okText="Ok" onIonChange={e => { setLojas(e.detail.value); }}>
                      {JSON.parse(window.localStorage.getItem("lojas") || "[]").map((valor: any) => (
                        <IonSelectOption value={valor.clienteLojaId}>{valor.clienteLojaNome}</IonSelectOption>
                      ))}
                    </IonSelect>
                  </IonChip>
                </IonItem>
                <IonItem className="calendar" lines='none' >
                  <IonLabel position="fixed">Data*</IonLabel>
                  <IonChip outline style={{ width: '100%' }}>
                    <IonIcon icon={calendar}></IonIcon>
                    <ReactDatePicker
                      onFocus={e => e.target.blur()}
                      dateFormat="dd/MM/yyyy"
                      locale={ptBR} portalId='agendamentoCadContent'
                      selected={startDate}
                      onChange={(date) => {
                        setStartDate(new Date((date || new Date()).setHours(0, 0, 0, 0)));

                      }}
                    />
                  </IonChip>
                </IonItem>
                <IonItem className="calendar" lines='none' >
                  <IonLabel position="fixed">Horário*</IonLabel>
                  <IonChip outline style={{ width: '100%' }}>
                    <IonIcon icon={time}></IonIcon>
                    <ReactDatePicker
                      onFocus={e => e.target.blur()}
                      selected={startTime}
                      locale={ptBR}
                      portalId='agendamentoCadContent'
                      onChange={(date) => setStartTime(date || new Date())}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={30}
                      timeCaption="Horário"
                      dateFormat="HH:mm"
                    />
                  </IonChip>
                </IonItem>
                <IonItem lines="none" >
                  <IonLabel position="fixed">CNPJ*</IonLabel>
                  <IonChip outline style={{ width: '100%', background: 'white' }}>
                    <IonInput type="tel" maxlength={18} placeholder="__.___.___/____-__" required value={CNPJ} onKeyUp={onChangeCNPJ} onIonInput={(e: any) => setCNPJ(e.target.value)} />
                  </IonChip>
                </IonItem>
                <IonItem lines="none">
                  <IonLabel position="fixed">Descrição*</IonLabel>
                  <IonChip outline style={{ width: '100%', background: 'white' }}>
                    <IonInput placeholder="Descrição" value={descricaoAgendamento} required onIonInput={(e: any) => setDescricaoAgendamento(e.target.value)}></IonInput>
                  </IonChip>
                </IonItem>
                <IonItem lines="none">
                  <IonLabel position="fixed">Pedido</IonLabel>
                  <IonChip outline style={{ width: '100%', background: 'white' }}>
                    <IonInput placeholder="Número Pedido" value={numeroPedido} required onIonInput={(e: any) => setNumeroPedido(e.target.value)}></IonInput>
                  </IonChip>
                </IonItem>
                <IonItem lines="none">
                  <IonLabel position="fixed">E-mail</IonLabel>
                  <IonChip outline style={{ width: '100%', background: 'white' }}>
                    <IonInput placeholder="E-mail" value={email} onIonInput={(e: any) => setEmail(e.target.value)} />
                  </IonChip>
                </IonItem>
                <IonItem lines='none'>
                  <IonLabel><IonButton onClick={() => cadastrar()} expand="block" type="submit">CADASTRAR</IonButton></IonLabel>
                </IonItem>
              </IonList>
            </IonCol>
          </IonRow>
        </IonGrid>

        <IonAlert isOpen={showIncluirAgendamento}
          onDidDismiss={() => setShowIncluirAgendamento(false)}
          header={'Cadastro de usuário'}
          message={'Você <strong>confirma</strong> inclusão?'}
          buttons={[
            {
              text: 'Cancelar',
              role: 'cancel',
            },
            {
              text: 'Confirmar',
              cssClass: 'primary',
              handler: () => {

                const objectRequest = {
                  cnpj: CNPJ.replace("-", "").replace(".", "").replace(".", "").replace("/", ""),
                  descricaoAgendamento: descricaoAgendamento,
                  numeroPedido: numeroPedido,
                  email: email.trim(),
                  dataAgendamento: startDate,
                  horaAgendamento: startTime.toLocaleTimeString().substring(0, 5),
                  lojas: JSON.parse(window.localStorage.getItem("lojas") || "[]").filter((value: any) => {
                    return lojas.includes(value.clienteLojaId);
                  })
                }

                requestService(cadastraAgendamento.url, {
                  method: cadastraAgendamento.method,
                  headers: [["token", localStorage.getItem("token") || ""]],
                  credentials: 'include',
                  body: JSON.stringify(objectRequest)
                },
                  history,
                  (response: any) => {
                    setDescricaoAgendamento("");
                    setCNPJ("");
                    setStartDate(new Date(new Date().setHours(0, 0, 0, 0)));
                    setStartTime(new Date(new Date().setHours(12, 0, 0, 0)));
                    setEmail("")
                    setTextToast("Cadastro realizado com sucesso.");
                    setShowSucesso(true);
                  },
                  (error: any) => {
                    setTextToast(error.message);
                    setShowErro(true);
                  });

              }
            }
          ]}
        />

        <IonAlert isOpen={showSucesso}
          onDidDismiss={() => { setShowSucesso(false); }}
          header={'Sucesso'}
          message={textToast}
          backdropDismiss={false}
          buttons={[
            {
              text: 'OK',
              handler: () => {
                setShowSucesso(false);
                props.closeAction();
              }
            }
          ]} />

        <IonAlert isOpen={showErro}
          onDidDismiss={() => setShowErro(false)}
          header={'Erro'}
          message={textToast}
          buttons={['OK']}
        />
      </IonContent>
    </>
  );
};

export default ({ agendamento, closeAction, closeActionSimple }: { agendamento: any, closeAction: Function, closeActionSimple: Function }) => (
  <AgendamentosCad agendamento={agendamento} closeAction={closeAction} closeActionSimple={closeActionSimple}>
  </AgendamentosCad>
)
