import { IonContent, IonPage, IonButton, IonInput, IonLabel, IonToast } from '@ionic/react';
import React, { useEffect, useRef, useState } from 'react';
import './ValidarCelular.css';
import imgTopo from './imgs/topo.png';
import { useHistory } from 'react-router-dom';
import { confirmarCadastro, requestService } from '../../../Utils/Services';


const ValidarCelular: React.FC = () => {

  const inputRef = useRef<any>(null);

    useEffect(() => {inputRef.current.setFocus()
        //setTimeout(() => inputRef.current.setFocus(), 100);
    })
  
  const history = useHistory();

  const [showToast, setShowToast] = useState(false);
  const [textToast, setTextToast] = useState("");
  const [codigo, setCodigo] = useState("");

  const validar = () => {
    const objectRequest = {
      cpf: window.localStorage.getItem("cpf"),
      telefone: window.localStorage.getItem("telefone"),
      otp: parseInt(codigo)
    }

    requestService(
      confirmarCadastro.url,
      {
        credentials: "include",
        method: confirmarCadastro.method,
        body: JSON.stringify(objectRequest)
      },
      history,
      (response:any) => {
        window.localStorage.clear();
        window.localStorage.setItem("logado", "true");
        history.replace("/cliente/operacoes");
      },
      (error:any) => {
        setTextToast("Token invalido");
        setShowToast(true);
        setCodigo("");
      }
    );
  }

  function onChangeCodigo(e: any){
    e.persist();
    e.preventDefault();
    setCodigo(e.target.value);

    if (e.keyCode === 13){
      validar();
    }
  }

 
  return (
    <IonPage>
      <IonContent className={"ValidarCelular"}>
        <div id="blocoTopo"></div>
        <img src={imgTopo} alt="Logo"/>
        <IonLabel className="titulo">Validando seu número</IonLabel>
        <IonLabel className="titulo">Insira o código que você irá receber em seu celular:</IonLabel>
        <div className="input">
          <span>Token</span>
          <IonInput ref={(ref) => inputRef.current = ref} type="number" tabIndex={0} maxlength={6} required value={codigo} onKeyUp={onChangeCodigo} />
        </div>
        <IonButton onClick={validar}>Validar Número</IonButton>
        <IonToast
        isOpen={showToast}
        position="middle" //top, bottom, middle
        onDidDismiss={() => setShowToast(false)}
        message={textToast}
        duration={3000}
        />
      </IonContent>
    </IonPage>
  );
};

export default ValidarCelular;
