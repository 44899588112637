import { IonBackButton, IonButton, IonButtons, IonContent, IonFooter, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonList, IonSearchbar, IonTitle, IonToast, IonToggle, IonToolbar } from '@ionic/react';
import { arrowBack } from 'ionicons/icons';
import React from 'react';
import { getNota } from '../../../../../Utils/Services';

class OperacoesPesquisar extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            notas: [],
            notaDigitada: '',
            pesquisando: false,
            pesquisou: false
        }
        this.getItems = this.getItems.bind(this);
    }

    /*
    componentWillUnmount() {
        this.setState({pesquisou: false, notaDigitada: '', notas: []});
      }
      */
    

    async getItems(filtro: any) {

        let nota = '000000000' + filtro.trim();
        nota = nota.substring(nota.length - 9);

        if (nota == '000000000') return;

        this.setState({ pesquisando: true, pesquisou: false });

        let token: string = "";
        const tkn = localStorage.getItem("token");
        if (typeof (tkn) === "string") {
            token = tkn;
        }

        await fetch(getNota.url.replace('{id}', nota),
            {
                method: getNota.method,
                headers: [
                    ["token", token]
                ],
                credentials: 'include'
            }).then(async (resp: Response) => {
                await resp.json().then((res: any) => {
                    this.setState({ notas: res.Items, pesquisou: true });
                });
            }).finally(() => this.setState({ pesquisando: false }));
    }

    render() {
        return (
            <>
                <IonHeader>
                    <IonToolbar color='primary'>
                        <IonButtons slot='start'>
                            <IonButton onClick={() => this.props.setShowModalPesquisar(false)}>
                                <IonIcon icon={arrowBack}></IonIcon>
                            </IonButton>
                        </IonButtons>
                        <IonTitle>
                            <IonLabel className="ion-text-wrap">Pesquisar pela nota fiscal</IonLabel>
                        </IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonContent>
                    <IonItem lines='none'>
                        <IonSearchbar inputmode='search' value={this.state.notaDigitada} onIonClear={(e) => { this.setState({ pesquisou: false, notaDigitada: '', notas: [] }) }} onKeyPress={(e) => { if (e.key == 'Enter') this.getItems(this.state.notaDigitada); }} onIonChange={(e) => { this.setState({ notaDigitada: e.detail.value }) }} placeholder='Digite o número da nota' ></IonSearchbar>
                        <IonButton slot='end' fill='clear' color='tertiary' onClick={
                            () => { this.getItems(this.state.notaDigitada); }
                        }>pesquisar</IonButton>
                    </IonItem>
                    <br />
                    <div style={{ width: '100%', textAlign: 'center' }}>
                        {this.state.pesquisando ? 'consultando...' : <OperacoesPesquisarLista pesquisou={this.state.pesquisou} notas={this.state.notas} router={this.props.router} setShowModalPesquisar={this.props.setShowModalPesquisar} />}
                    </div>
                </IonContent>
            </>);
    }
}

class OperacoesPesquisarLista extends React.Component<any, any> {
    render() {
        return (
            this.props.pesquisou ?
                (this.props.notas && this.props.notas.hasOwnProperty('length') && this.props.notas.length > 0) ?
                    <IonList>
                        {this.props.notas.map((valor: any) => (
                            <IonItem lines='full' button={true} detail={true} onClick={() => { this.props.setShowModalPesquisar(false); this.props.router.push("operacoes/acompanhar", { estabelecimento: "A", codigoBarras: "B", operacaoid: valor.sk.replace('operacao#', '').replace(/#/g, '%23') }) }}>
                                <IonLabel>
                                    <b>Nota - {valor.numeroNotaFiscal}</b>
                                    <p>{valor.cnpj + ' - ' + valor.razaoSocial}</p>
                                    <p>{valor.sk}</p>
                                </IonLabel>
                            </IonItem>
                        ))}
                    </IonList> :
                    <div style={{ width: '100%', textAlign: 'center' }}><br></br>Nota não encontrada</div> : <></>)
    }
}

export default OperacoesPesquisar;

