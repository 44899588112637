import './OperacoesTable.css';

function Coluna(props: any) {
    if (props.linha == 0) {
        return (<th>{props.model}</th>);
    }
    else {
        return (<td>{props.model}</td>);
    }
}

function Linha(props: any) {
    return (<tr>
        <Coluna model={props.model[0]} linha={props.linha} />
        <Coluna model={props.model[1]} linha={props.linha} />
        <Coluna model={props.model[2]} linha={props.linha} />
        <Coluna model={props.model[3]} linha={props.linha} />
        <Coluna model={props.model[4]} linha={props.linha} />
        <Coluna model={props.model[5]} linha={props.linha} />
        <Coluna model={props.model[6]} linha={props.linha} />
        <Coluna model={props.model[7]} linha={props.linha} />
        <Coluna model={props.model[8]} linha={props.linha} />
        <Coluna model={props.model[9]} linha={props.linha} />
        <Coluna model={props.model[10]} linha={props.linha} />
    </tr>
    )
}

export default function OperacoesTable(props: any) {
    if (props && props.model && props.model.length > 1) {
        return (
            <table className="center">
                {props.model.map((valor: any, index: any) => {
                    return <Linha model={valor} linha={index} />
                })}
            </table>
        );
    }
    else {
        return (
            <div>Não há dados para a data pesquisada</div>
        )
    }
}